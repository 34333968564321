import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext"
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { FaPerson } from "react-icons/fa6";
import { FaRankingStar } from "react-icons/fa6";
import { PiHandWithdraw } from "react-icons/pi";
import { PiHandDeposit } from "react-icons/pi";
import first from "../../imges/1.jpeg"
import first1 from "../../imges/2.jpeg"

import first2 from "../../imges/3.jpeg"
import first3 from "../../imges/4.jpeg"
import first4 from "../../imges/5.jpeg"
import { useTranslation } from "react-i18next";
import { FaArrowDown } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";

function Dashboard() {

    let { logOut } = useContext(AgentContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true
        });
    }

    let { data } = useQuery("getCurrentAgent", getCurrentAgent);
    console.log(data?.data.data)
    function getAgentHistory() {
        return axios.get(`https://together-v.com/api/v1/agent/AgentBalanceHistory`, {
            withCredentials: true
        });
    }

    let response = useQuery("getAgentHistory", getAgentHistory);
    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Dashboard</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>
                </div>
                <div className="d-flex mx-auto">
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span>{t("language")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("en")}
                                >
                                    English
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("ar")}
                                >
                                    العربية
                                </button>
                            </li>
                        </ul>
                    </li>
                </div>


                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">
                        <li className="nav-item dropdown ">
                            <a className="nav-link nav-profile d-flex align-items-center " href="#" data-bs-toggle="dropdown">
                                {data?.data.data.profileImage ? (
                                    <img
                                        src={data?.data.data.profileImage}
                                        alt="Profile"
                                        className="rounded-circle"
                                    />
                                ) : (
                                    <CgProfile size={30} className="rounded-circle" />
                                )}
                                <span className="d-none d-md-block dropdown-toggle ps-2">
                                    {data?.data.data.firstName} {data?.data.data.secondName}
                                </span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>{t("my_profile")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>{t("sign_out")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <h2 className="menu-icon " onClick={toggleSidebar}>
                    <FiAlignJustify />
                </h2>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-tree"></i>
                            <span>{t("my_tree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-person-plus"></i>
                            <span>{t("add_agent")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box"></i>
                            <span>{t("all_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box"></i>
                            <span>{t("my_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-wallet2"></i>
                            <span>{t("my_wallet")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-arrow-left-right"></i>
                            <span>{t("transfers")}</span>
                        </Link>
                    </li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-file-earmark-text"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    {t("balance_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    {t("transfer_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    {t("withdraw_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/BinaryDetailsAgent">
                                    {t("binary_details")}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-cash"></i>
                            <span>{t("withdraw")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/profile">
                            <i className="bi bi-person"></i>
                            <span>{t("my_profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-calendar"></i>
                            <span>{t("all_events")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-telephone"></i>
                            <span>{t("contact_us")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <div id="main" className="bg-light-2">
                <div className="pagetitle">
                    <h1>{t("dashboard")}</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/dashboard">{t("home")}</a>
                            </li>
                            <li className="breadcrumb-item active">{t("dashboard")}</li>
                        </ol>
                    </nav>
                </div>
                <div>
                    <div className="container mt-4">
                        <h2 className="my-4">{t("welcome")}: {data?.data.data.firstName} {data?.data.data.secondName} {data?.data.data.thirdName} {data?.data.data.lastName}</h2>
                        <div className="row">

                            <div className="col-md-4 mt-3">
                                <div className="card text-center">
                                    <div className=" d-flex align-items-center" id="agentsNum">
                                        <div className="col-md-6  w-50">
                                            <img src={first} alt="" />
                                        </div>

                                        <div className=" col-sm-6 m-auto">
                                            <h3>{data?.data.data.agentLeftCounter}</h3>
                                            <h5 className="card-title">{t("left")}</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="card text-center">
                                    <div className=" d-flex align-items-center" id="agentsNum">
                                        <div className="col-md-6 w-50">
                                            <img src={first1} alt="" />
                                        </div>
                                        <div className=" col-sm-6 m-auto">
                                            <h3>{data?.data.data.agentRightCounter}</h3>
                                            <h5 className="card-title">{t("right")}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="card text-center">
                                    <div className=" d-flex align-items-center" id="agentsNum">
                                        <div className="col-md-6  w-50">
                                            <img src={first2} alt="" />
                                        </div>
                                        <div className=" col-sm-6 m-auto">

                                            <h3>{data?.data.data.pv}</h3>
                                            <h5 className="card-title">{t("pv")}</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="card text-center">
                                    <div className=" color-green d-flex align-items-center" id="agentsNum">
                                        <div className="col-md-6   w-50">
                                            <img src={first3} alt="" />
                                        </div>
                                        <div className=" col-sm-6 m-auto">

                                            <h3>{data?.data.data.totalMoney}</h3>
                                            <h5 className="card-title">{t("total_money_in")}</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4 mt-3  ">
                                <div className="card text-center">
                                    <div className=" color-red  d-flex align-items-center" id="agentsNum">
                                        <div className="col-md-6   w-50">
                                            <img src={first4} alt="" />
                                        </div>
                                        <div className=" col-md-6 m-auto">

                                            <h3>{data?.data.data.totalWithdraw}</h3>
                                            <h5 className="card-title">{t("total_money_out")}</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-5 mt-3">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h3>{data?.data.data.id}</h3>
                                        <h5 className="card-title">{t("id")}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>


        </>
    );
}

export default Dashboard;
