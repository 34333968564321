import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext"
import logoo from "../../imges/logo.png"
import { IoIosSettings } from "react-icons/io";

import { FiAlignJustify } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { CgProfile } from "react-icons/cg";

function ViewAgentEventss() {
    let { logOut } = useContext(AgentContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();
    let params = useParams();
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    function getevet() {
        return axios.get(`https://together-v.com/api/v1/event/getEvent/${params.id}`, {
            withCredentials: true
        });
    }
    let { data } = useQuery('getevet', getevet);
    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true
        });
    }

    let { data: prof } = useQuery("getCurrentAgent", getCurrentAgent);
    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Events</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>

                </div>
                <div className="d-flex mx-auto">
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link "
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span>{t("language")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("en")}
                                >
                                    English
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("ar")}
                                >
                                    العربية
                                </button>
                            </li>
                        </ul>
                    </li>
                </div>


                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">
                        <li className="nav-item dropdown">
                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                {prof?.data.data.profileImage ? (
                                    <img
                                        src={prof?.data.data.profileImage}
                                        alt="Profile"
                                        className="rounded-circle"
                                    />
                                ) : (
                                    <CgProfile size={30} className="rounded-circle" />
                                )}
                                <span className="d-none d-md-block dropdown-toggle ps-2">
                                    {prof?.data.data.firstName} {prof?.data.data.secondName}
                                </span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>{t("my_profile")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()}>
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>{t("sign_out")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>

                <h2 className="menu-icon" onClick={toggleSidebar}>
                    <FiAlignJustify />
                </h2>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-tree"></i>
                            <span>{t("my_tree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-person-plus"></i>
                            <span>{t("add_agent")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box"></i>
                            <span>{t("all_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box"></i>
                            <span>{t("my_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-wallet2"></i>
                            <span>{t("my_wallet")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-arrow-left-right"></i>
                            <span>{t("transfers")}</span>
                        </Link>
                    </li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-file-earmark-text"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    {t("balance_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    {t("transfer_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    {t("withdraw_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/BinaryDetailsAgent">
                                    {t("binary_details")}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-cash"></i>
                            <span>{t("withdraw")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/profile">
                            <i className="bi bi-person"></i>
                            <span>{t("my_profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-calendar"></i>
                            <span>{t("all_events")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-telephone"></i>
                            <span>{t("contact_us")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>{t("eventDetails")}</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="index.html">{t("home")}</a>
                                </li>
                                <li className="breadcrumb-item">{t("dashboard")}</li>
                                <li className="breadcrumb-item active">{t("eventDetails")}</li>
                            </ol>
                        </nav>
                    </div>

                    <div className="body genealogy-body genealogy-scroll">
                        <div className="container">
                            <div className="row">
                                <div key={data?.data.data._id} className="row">
                                    {/* Image Section */}
                                    <div className="col-lg-6">
                                        <div>
                                            <img className="imp w-100" src={data?.data.data.img} alt="Event" />
                                        </div>
                                    </div>

                                    {/* Video Section */}
                                    <div className="col-lg-6">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: data?.data.data.iframe,
                                            }}
                                        ></div>
                                    </div>



                                    {/* Text Content */}
                                    <div
                                        className={`card-body ${i18n.language === "ar" ? "text-end" : "text-start"}`}
                                    >
                                        <p>{data?.data.data.category}</p>
                                        <h3>{data?.data.data.name}</h3>
                                        <h5 className="mt-3">
                                            {t("title")}: {data?.data.data.title}
                                        </h5>
                                        <h5 className="mt-3">
                                            {t("description")}:
                                            <div className="description-container">
                                                {data?.data.data.description
                                                    .split(" ")
                                                    .reduce((acc, word, index) => {
                                                        const lineIndex = Math.floor(index / 6);
                                                        if (!acc[lineIndex]) acc[lineIndex] = [];
                                                        acc[lineIndex].push(word);
                                                        return acc;
                                                    }, [])
                                                    .map((line, index) => (
                                                        <div key={index}>{line.join(" ")}</div>
                                                    ))}
                                            </div>
                                        </h5>
                                        <h5 className="mt-3">
                                            {t("address")}: {data?.data.data.address}
                                        </h5>
                                        <h6 className="mt-3">{t("created_at")}: {data?.data.data.createdAt.split("T")[0]}</h6>

                                        <h6 className="mt-3">{t("event_date")}: {data?.data.data.eventDate.split("T")[0]}</h6>
                                    </div>
                                </div>





                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default ViewAgentEventss;
