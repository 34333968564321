import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useQuery, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AdminContext } from "../../Context/AdminContext";
import gold from "../../imges/gold.jpg";
import red from "../../imges/red.png";
import silver from "../../imges/silver.avif";
import bronze from "../../imges/bronze.jpg";
import logoo from "../../imges/logo.png";
import { FiAlignJustify } from "react-icons/fi";
import { BallTriangle } from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
function UpdateDataAgent() {
  let navigate = useNavigate();
  let { logOut } = useContext(AdminContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);
  let params = useParams();
  const queryClient = useQueryClient();
  const [addValue, setAddValue] = useState("");
  const [removeValue, setRemoveValue] = useState("");
  const [addPVValue, setAddPVValue] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassEWallet, setNewPassEWallet] = useState("");
  const [visibility, setVisibility] = useState({});
  const [selectedMember, setSelectedMember] = useState(null); // For modal
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchId, setSearchId] = useState(""); // Search input value
  const [filteredTree, setFilteredTree] = useState(null); // State for filtered tree
  const [isSearching, setIsSearching] = useState(false); // Search loading state
  const [selectedMemberLeft, setSelectedMemberLeft] = useState(null); // For modal
  const [selectedMemberRight, setSelectedMemberRight] = useState(null); // For modal
  const { t, i18n } = useTranslation(); // Translation hook
  const [loadingAddBalance, setLoadingAddBalance] = useState(false);
  const [loadingRemoveBalance, setLoadingRemoveBalance] = useState(false);
  const [loadingAddPv, setLoadingAddPv] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true); // Start loading
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/getAgentByAdmin/${params.id}`,
        {
          withCredentials: true,
        }
      );
      setData(response.data); // Set data
    } catch (error) {
      toast.error("Failed to fetch data. Please try again.");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchData();
  }, [params.id]); // Dependency on params.id



  const handleAddBalance = async () => {
    setLoadingAddBalance(true);
    try {
      const response = await axios.post(
        `https://together-v.com/api/v1/agent/addBalance/${params.di}`,
        { value: addValue },
        { withCredentials: true }
      );

      if (response.data.message === "Balance Updated successfully") {
        toast.success("Balance added successfully!");
        queryClient.invalidateQueries("getDataForUpdate");
        await fetchData(); // Fetch updated data
      }
    } catch (error) {
      toast.error("Failed to add balance. Please try again.");
    } finally {
      setLoadingAddBalance(false);
    }
  };

  const handleRemoveBalance = async () => {
    setLoadingRemoveBalance(true);

    try {
      const response = await axios.post(
        `https://together-v.com/api/v1/agent/deleteBalance/${params.di}`,
        { value: removeValue },
        { withCredentials: true }
      );

      if (response.data.message === "Balance Updated successfully") {
        toast.success("Balance removed successfully!");
        queryClient.invalidateQueries("getDataForUpdate");
        await fetchData(); // Fetch updated data
      }
    } catch (error) {
      toast.error("Failed to remove balance. Please try again.");
    } finally {
      setLoadingRemoveBalance(false);
    }
  };
  const handleAddPv = async () => {
    setLoadingAddPv(true);

    try {
      // Convert addPVValue to a number
      const numericValue = Number(addPVValue);

      if (isNaN(numericValue)) {
        toast.error("Invalid PV value. Please enter a valid number.");
        return;
      }

      const response = await axios.post(
        `https://together-v.com/api/v1/agent/addPV/${params.di}`,
        { value: numericValue }, // Send as a number
        { withCredentials: true }
      );

      if (response.data.message === "PV Updated successfully") {
        toast.success("PV added successfully!");

        // Log the type of the added value
        console.log("Type of added PV value:", typeof numericValue);

        queryClient.invalidateQueries("getDataForUpdate");
        await fetchData(); // Fetch updated data
      }
    } catch (error) {
      toast.error("Failed to add PV. Please try again.");
      console.error("Error adding PV:", error);
    }
    finally {
      setLoadingAddPv(false);
    }
  };


  const handleNewPassword = async () => {
    try {
      const response = await axios.post(
        `https://together-v.com/api/v1/agent/updatePasswordByAdmin/${params.di}`,
        { newPass: newPass },
        { withCredentials: true }
      );

      if (response.data.message === "Password updated successfully") {
        toast.success("Password updated successfully!");
        queryClient.invalidateQueries("getDataForUpdate");
      }
    } catch (error) {
      toast.error("Failed to update Password. Please try again.");
      queryClient.invalidateQueries("getDataForUpdate");
    }
  };

  const handleNewPasswordEWallet = async () => {
    try {
      const response = await axios.post(
        `https://together-v.com/api/v1/agent/updateWalletPasswordByAdmin/${params.di}`,
        { newPass: newPassEWallet },
        { withCredentials: true }
      );

      if (response.data.message === "Password updated successfully") {
        toast.success("Password updated successfully!");
        queryClient.invalidateQueries("getDataForUpdate");
      }
    } catch (error) {
      toast.error("Failed to update Password. Please try again.");
      queryClient.invalidateQueries("getDataForUpdate");
    }
  };


  const fetchMemberDetails = async (memberId) => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/getAgentByAdmin/${memberId}`,
        { withCredentials: true }
      );
      setSelectedMember(response.data.data); // Set the selected member's data
    } catch (error) {
      toast.error("Failed to fetch member details.");
    }
  };

  function getAgentTree() {
    return axios.get(
      `https://together-v.com/api/v1/agent/getAgentTreeForAdmin/${params.id}`,
      {
        withCredentials: true,
      }
    );
  }
  const { data: treeData, isLoading: isTreeLoading } = useQuery(
    "getAgentTree",
    getAgentTree
  );
  console.log(treeData?.data.data)
  const renderFixedTree = (treeData) => {
    const getImageByLevel = (level) => {
      switch (level) {
        case "red":
          return red;
        case "gold":
          return gold;
        case "silver":
          return silver;
        case "bronze":
          return bronze;
        default:
          return "assets/img/default.jpg";
      }
    };
    const navigateToAddAgent = (parentId, position) => {
      navigate(`/addagentadmin?parentId=${parentId}&position=${position}`);
    };

    return (
      <ul style={{ listStyleType: "none", textAlign: "center", width: "100%" }} className="mt-3 mb-5">
        {/* Root Node */}
        <li>

          <div className="member-view-boxx" style={{
            textAlign: "center",
            marginBottom: "10px",
            width: "100%",
          }}>
            <div
              className="member-image mt-4"
              style={{
                backgroundColor: "#31373a",
                width: "22%",
                height: "100px", // Adjusted height for better visibility
                borderRadius: "10px",
                margin: "auto"
              }}
            >
              <img
                src={getImageByLevel(treeData?.level)}
                alt="root"
                style={{ borderRadius: "50%", width: "50px", height: "50px", cursor: "pointer" }
                } onClick={() => {
                  if (treeData?.parent !== "000000000000000000000000") {
                    navigate(`/treeDetails/${data?.parent}`);
                  }
                }}
              />
              <h3 onClick={() => fetchMemberDetails(treeData?.id)} className="fnt-10px" style={{ color: "white", marginTop: "10px", cursor: "pointer" }}>
                01: {treeData?.id}
              </h3>
            </div>


            {/* First Level: 02 and 03 */}
            <ul style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <li style={{ margin: "0 20px" }}>

                <div className="member-view-boxx" style={{
                  textAlign: "center",
                  marginBottom: "10px",
                  width: "100%",
                }}>
                  <div
                    className="member-image mt-4"
                    style={{
                      backgroundColor: "#31373a",
                      width: "50%",
                      height: "100px", // Adjusted height for better visibility
                      borderRadius: "10px",
                      margin: "auto"
                    }}
                  >
                    <img
                      src={
                        treeData?.level === "bronze"
                          ? red
                          : treeData?.level === "silver"
                            ? silver
                            : treeData?.level === "gold"
                              ? gold
                              : treeData?.level === "red"
                                ? red
                                : "assets/img/default.jpg" // Fallback if no level matches
                      }
                      alt="02"
                      style={{ borderRadius: "50%", width: "50px", height: "50px" }}
                    />
                    <h3 onClick={() => fetchAdditionalData(treeData?.id)} className="fnt-10px" style={{ color: "white", marginTop: "10px", cursor: "pointer" }}>
                      02: {treeData?.id}
                    </h3>
                  </div>
                </div>

                {/* Children of 02 */}
                <ul style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                  <li style={{ margin: "0 20px" }}>
                    <div className="member-view-boxx">
                      <div
                        className="member-image my-4"
                        style={{
                          backgroundColor: "#31373a",
                          width: "100%",
                          height: "100px",
                          borderRadius: "10px",
                          margin: "auto"
                        }}
                      >
                        {treeData?.leftChild0 ? (
                          <>
                            <img
                              src={getImageByLevel(treeData?.leftChild0?.level)}
                              alt="child1"
                              style={{ borderRadius: "50%", width: "50px", height: "50px", cursor: "pointer" }}
                              onClick={() => navigate(`/treeDetails/${treeData?.leftChild0?.id}`)}
                            />
                            <h3
                              onClick={() => fetchMemberDetails(treeData?.leftChild0?.id)}
                              className="fnt-10px"
                              style={{ color: "white", marginTop: "10px", cursor: "pointer" }}
                            >
                              01: {treeData?.leftChild0?.id}
                            </h3>
                          </>
                        ) : (
                          // "+" Button only shown when leftChild0 does not exist
                          <button
                            className="btn btn-primary btn-sm"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%"
                            }}
                            onClick={() => navigateToAddAgent(treeData?.id, "left0")}
                          >
                            +
                          </button>
                        )}
                      </div>
                    </div>
                  </li>

                  <li style={{ margin: "0 20px" }}>
                    <div className="member-view-boxx">
                      <div
                        className="member-image my-4"
                        style={{
                          backgroundColor: "#31373a",
                          width: "100%",
                          height: "100px",
                          borderRadius: "10px",
                          margin: "auto"
                        }}
                      >
                        {treeData?.rightChild0 ? (
                          <>
                            <img
                              src={getImageByLevel(treeData?.rightChild0?.level)}
                              alt="child2"
                              style={{ borderRadius: "50%", width: "50px", height: "50px", cursor: "pointer" }}
                              onClick={() => navigate(`/treeDetails/${treeData?.rightChild0?.id}`)}
                            />
                            <h3
                              onClick={() => fetchMemberDetails(treeData?.rightChild0?.id)}
                              className="fnt-10px"
                              style={{ color: "white", marginTop: "10px", cursor: "pointer" }}
                            >
                              01: {treeData?.rightChild0?.id}
                            </h3>
                          </>
                        ) : (
                          // "+" Button only shown when rightChild0 does not exist
                          <button
                            className="btn btn-primary btn-sm"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%"
                            }}
                            onClick={() => navigateToAddAgent(treeData?.id, "right0")}
                          >
                            +
                          </button>
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              </li>

              <li style={{ margin: "0 20px" }}>
                <div className="member-view-boxx">
                  <div
                    className="member-image mt-4"
                    style={{
                      backgroundColor: "#31373a",
                      width: "100%",
                      height: "100px", // Adjusted height for better visibility
                      borderRadius: "10px",
                      margin: "auto"
                    }}
                  >

                    <img
                      src={
                        treeData?.level === "bronze"
                          ? red
                          : treeData?.level === "silver"
                            ? red
                            : treeData?.level === "gold"
                              ? gold
                              : treeData?.level === "red"
                                ? red
                                : "assets/img/default.jpg" // Fallback if no level matches
                      } alt="03"
                      style={{ borderRadius: "50%", width: "50px", height: "50px" }}
                    />
                    <h3 onClick={() => fetchAdditionalDataRight(treeData?.id)} className="fnt-10px" style={{ color: "white", marginTop: "10px", cursor: "pointer" }}>
                      03: {treeData?.id}
                    </h3>
                  </div>
                </div>

                {/* Children of 03 */}
                <ul style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                  <li style={{ margin: "0 20px" }}>
                    <div className="member-view-boxx">
                      <div
                        className="member-image mt-4"
                        style={{
                          backgroundColor: "#31373a",
                          width: "100%",
                          height: "100px", // Adjusted height for better visibility
                          borderRadius: "10px",
                          margin: "auto"
                        }}
                      >
                        {treeData?.leftChild1 ? (<>
                          <img
                            src={getImageByLevel(treeData?.leftChild1?.level)}
                            alt="child3"
                            style={{ borderRadius: "50%", width: "50px", height: "50px", cursor: "pointer" }}
                            onClick={() => navigate(`/treeDetails/${treeData?.leftChild1?.id}`)}
                          />
                          <h3 onClick={() => fetchMemberDetails(treeData?.leftChild1?.id)} className="fnt-10px" style={{ color: "white", marginTop: "10px", cursor: "pointer" }}>
                            01: {treeData?.leftChild1?.id}
                          </h3>
                        </>) : (
                          // "+" Button only shown when rightChild0 does not exist
                          <button
                            className="btn btn-primary btn-sm"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%"
                            }}
                            onClick={() => navigateToAddAgent(treeData?.id, "left1")}
                          >
                            +
                          </button>
                        )}

                      </div>
                    </div>
                  </li>
                  <li style={{ margin: "0 20px" }}>
                    <div className="member-view-boxx">
                      <div
                        className="member-image mt-4"
                        style={{
                          backgroundColor: "#31373a",
                          width: "100%",
                          height: "100px", // Adjusted height for better visibility
                          borderRadius: "10px",
                          margin: "auto"
                        }}
                      >
                        {treeData?.rightChild1 ? (<>
                          <img
                            src={getImageByLevel(treeData?.rightChild1?.level)}
                            alt="child4"
                            style={{ borderRadius: "50%", width: "50px", height: "50px", cursor: "pointer" }}
                            onClick={() => navigate(`/treeDetails/${treeData?.rightChild1?.id}`)}
                          />
                          <h3 onClick={() => fetchMemberDetails(treeData?.rightChild1?.id)} className="fnt-10px" style={{ color: "white", marginTop: "10px", cursor: "pointer" }}>
                            01: {treeData?.rightChild1?.id}
                          </h3>
                        </>) : (
                          // "+" Button only shown when rightChild0 does not exist
                          <button
                            className="btn btn-primary btn-sm"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%"
                            }}
                            onClick={() => navigateToAddAgent(treeData?.id, "right1")}
                          >
                            +
                          </button>
                        )}

                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    );
  };

  const handleDelete = async () => {
    try {
      await axios.get(`https://together-v.com/api/v1/agent/deleteAgent/${params.di}`, { withCredentials: true });
      toast.success("Agent deleted successfully!");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to delete agent.");
      console.log(error)
    }

  };

  const handleBlockActivate = async () => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/blockPackageActivate/${params.di}`,
        { withCredentials: true }
      );

      if (response.data.message === "Agent blocked successfully") {
        toast.success("Agent blocked successfully!");
        queryClient.invalidateQueries("getDataForUpdate");
      }
    } catch (error) {
      toast.error("Failed to blocke Agent. Please try again.");
      queryClient.invalidateQueries("getDataForUpdate");
    }
  };

  const handleBlockLogin = async () => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/blockLogin/${params.di}`,
        { withCredentials: true }
      );

      if (response.data.message === "Agent blocked successfully") {
        toast.success("Agent blocked successfully!");
        queryClient.invalidateQueries("getDataForUpdate");
      }
    } catch (error) {
      toast.error("Failed to block Agent. Please try again.");
      queryClient.invalidateQueries("getDataForUpdate");
    }
  };

  const handleBlock = async () => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/terminate/${params.di}`,
        { withCredentials: true }
      );

      if (response.data.message === "Agent blocked successfully") {
        toast.success("Agent Terminated successfully!");
        queryClient.invalidateQueries("getDataForUpdate");
      }
    } catch (error) {
      toast.error("Failed to Terminate Agent. Please try again.");
      queryClient.invalidateQueries("getDataForUpdate");
    }
  };

  const handleUnBlock = async () => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/unterminated/${params.di}`,
        { withCredentials: true }
      );

      if (response.data.message === "Agent unblocked successfully") {
        toast.success("Agent unblocked successfully!");
        queryClient.invalidateQueries("getDataForUpdate");
      }
    } catch (error) {
      toast.error("Failed to unblock Agent. Please try again.");
      queryClient.invalidateQueries("getDataForUpdate");
    }
  };

  const handleUnBlockLogin = async () => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/unblockLogin/${params.di}`,
        { withCredentials: true }
      );

      if (response.data.message === "Agent unblocked successfully") {
        toast.success("Agent unblocked successfully!");
        queryClient.invalidateQueries("getDataForUpdate");
      }
    } catch (error) {
      toast.error("Failed to unblock Agent. Please try again.");
      queryClient.invalidateQueries("getDataForUpdate");
    }
  };

  const handleUnBlockActivate = async () => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/unblockPackageActivate/${params.di}`,
        { withCredentials: true }
      );

      if (response.data.message === "Agent unblocked successfully") {
        toast.success("Agent unblocked successfully!");
        queryClient.invalidateQueries("getDataForUpdate");
      }
    } catch (error) {
      toast.error("Failed to unblock Agent. Please try again.");
      queryClient.invalidateQueries("getDataForUpdate");
    }
  };

  const fetchAdditionalData = async (memberId) => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/getAgentByAdmin/${memberId}`,
        { withCredentials: true }
      );
      setSelectedMemberLeft(response.data.data);
      console.log("Agenttt Details:", response.data.data);

      // Log the fetched agent details to the console
    } catch (error) {
      toast.error("Failed to fetch member details.");
      console.error("Error fetching member details:", error);
    }
  };


  const fetchAdditionalDataRight = async (memberId) => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/getAgentByAdmin/${memberId}`,
        { withCredentials: true }
      );
      setSelectedMemberRight(response.data.data);
      console.log("left", response.data.data)
      // Log the fetched agent details to the console
    } catch (error) {
      toast.error("Failed to fetch member details.");
      console.error("Error fetching member details:", error);
    }
  };
  useEffect(() => {
    if (i18n.language === "ar") {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }

    // Cleanup on unmount
    return () => {
      document.body.classList.remove("rtl");
    };
  }, [i18n.language]);

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <Helmet>
          <meta charset="utf-8" />
          <meta
            content="width=device-width, initial-scale=1.0"
            name="viewport"
          />
          <title>Agent Data</title>
          <meta content="" name="description" />
          <meta content="" name="keywords" />
        </Helmet>
        <div className="d-flex align-items-center justify-content-between w-100">
          {/* Logo */}
          <div>
            <img
              src={logoo}
              alt="Logo"
              className="logo d-flex align-items-center w-35"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/DashboardAdmin")}
            />
          </div>

          {/* Language Toggle Buttons */}
          <div className="d-flex">
            <li className="nav-item dropdown bg-body">
              <Link
                className="nav-link "
                to="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{t("language")}</span>
              </Link>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <button
                    className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                    onClick={() => changeLanguage("ar")}
                  >
                    العربية
                  </button>
                </li>

              </ul>
            </li>

          </div>

          {/* Sidebar Toggle */}
          <h2 className="menu-icon" onClick={toggleSidebar}>
            <FiAlignJustify />
          </h2>
        </div>
      </header>

      <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link" to="/DashboardAdmin">
              <i className="bi bi-grid"></i>
              <span>{t("dashboard")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/Agents">
              <i className="bi bi-grid"></i>
              <span>{t("allAgents")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/NewId">
              <i className="bi bi-grid"></i>
              <span>{t("changeID")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/AddEpin">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("addEpin")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/EPin">
              <i className="bi bi-grid"></i>
              <span>E-Pins</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/EpinHistory">
              <i className="bi bi-grid"></i>
              <span>{t("ePinsHistory")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/AddProduct">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("addProduct")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/AllProducts">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("allProducts")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/MyTreeAdmin">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("myTree")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/AddEvent">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("addEvents")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/ViewAllEventsAdmin">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("viewEvents")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/WithDrawRequest">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("withdrawRequests")}</span>
            </Link>
          </li>

          <li className="nav-item dropdown bg-body">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("reports")}</span>
            </Link>
            <ul className="dropdown-menu">
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/MyTransfers">
                  <i className="bi bi-box-arrow-in-down"></i>
                  <span>{t("myTransfers")}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                  <i className="bi bi-box-arrow-in-down"></i>
                  <span>{t("allPurchase")}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/TransferRequests">
                  <i className="bi bi-box-arrow-in-down"></i>
                  <span>{t("allTransfers")}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                  <i className="bi bi-box-arrow-in-down"></i>
                  <span>{t("allWithdraws")}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/BinaryReports">
                  <i className="bi bi-box-arrow-in-down"></i>
                  <span>{t("binaryDetails")}</span>
                </Link>
              </li>
            </ul>
          </li>


          <li className="nav-item">
            <Link className="nav-link collapsed" to="/Filtration">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("filtration")}</span>
            </Link>
          </li>
          <li className="nav-heading">{t("pages")}</li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/AllContactUs">
              <i className="bi bi-person"></i>
              <span>{t("allContact")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/ProfileAdmin">
              <i className="bi bi-person"></i>
              <span>{t("profile")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" onClick={() => logOut()}>
              <i className="bi bi-person"></i>
              <span>{t("logout")}</span>
            </Link>
          </li>
        </ul>
      </aside>

      <div id="main" className="bg-light-2">
        <div className="container mt-4">
          <section className="section profile">
            <div className="row justify-content-between mx-1 mt-4">
              <div className="col-md-3">
                <button className="btn btn-danger mt-2 w-100" onClick={handleBlockActivate}> {t("blockActivate")}</button>
              </div>
              <div className="col-md-3">
                <button
                  className="btn btn-danger mt-2 w-100"
                  onClick={handleBlockLogin}
                >
                  {t("blockLogin")}
                </button> </div>
              <div className="col-md-3"><button
                className="btn btn-danger mt-2 w-100 "
                onClick={handleBlock}
              >
                {t("block")}
              </button> </div>
              <div className="col-md-3"><button
                className="btn btn-danger mt-2 w-100 "
                onClick={handleDelete}
              >
                {t("delete")}
              </button> </div>
            </div>

            <div className="row justify-content-between mx-1 mt-4">
              <div className="col-md-3">
                <button className="btn btn-success mt-2 w-100" onClick={handleUnBlockActivate}> {t("unBlockActivate")}</button>
              </div>
              <div className="col-md-3">
                <button
                  className="btn btn-success mt-2 w-100"
                  onClick={handleUnBlockLogin}
                >
                  {t("unBlockLogin")}
                </button> </div>
              <div className="col-md-3"><button
                className="btn btn-success mt-2 w-100 "
                onClick={handleUnBlock}
              >
                {t("unBlock")}
              </button> </div>

            </div>


            <div className="row justify-content-between">
              <div className="col-xl-12">
                <div className="card border-0">
                  <div className="card-body p-3">
                    <ul className="nav nav-tabs border-bottom" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="overview-tap"
                          data-bs-toggle="pill"
                          data-bs-target="#profile-overview"
                          type="button"
                          role="tab"
                          aria-controls="profile-overview"
                          aria-selected="true"
                        >
                          {t("editData")}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="edit-tap"
                          data-bs-toggle="pill"
                          data-bs-target="#profile-edit"
                          type="button"
                          role="tab"
                          aria-controls="profile-edit"
                          aria-selected="false"
                        >
                          {t("addRemoveBalancePV")}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="change-password"
                          data-bs-toggle="pill"
                          data-bs-target="#profile-change-password"
                          type="button"
                          role="tab"
                          aria-controls="profile-change-password"
                          aria-selected="false"
                        >
                          {t("changePassword")}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="change-eWallet"
                          data-bs-toggle="pill"
                          data-bs-target="#profile-change-eWallet"
                          type="button"
                          role="tab"
                          aria-controls="profile-change-eWallet"
                          aria-selected="false"
                        >
                          {t("eWalletPassword")}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="agent-tree"
                          data-bs-toggle="pill"
                          data-bs-target="#profile-agent-tree"
                          type="button"
                          role="tab"
                          aria-controls="profile-agent-tree"
                          aria-selected="false"
                        >
                          {t("agentTree")}
                        </button>
                      </li>
                    </ul>

                    <div className="tab-content pt-2">
                      <div
                        class="tab-pane fade profile-overview show active"
                        id="profile-overview"
                        role="tabpanel"
                        aria-labelledby="#overview-tap"
                      >
                        <form
                          onSubmit={async (e) => {
                            e.preventDefault();
                            const updatedData = {
                              firstName: e.target.firstName.value,
                              secondName: e.target.secondName.value,
                              thirdName: e.target.thirdName.value,
                              lastName: e.target.lastName.value,
                              email: e.target.email.value,
                              phone: e.target.phone.value,
                              nationalId: e.target.nationalId.value,
                              address: e.target.address.value,
                              country: e.target.country.value,
                              governorate: e.target.governorate.value,
                              city: e.target.city.value,
                              inheritorName: e.target.inheritorName.value,
                              grandfatherInheritorName:
                                e.target.grandfatherInheritorName.value,
                              zipCode: e.target.zipCode.value,
                            };

                            try {
                              const token = localStorage.getItem("AdminToken");

                              const { data } = await axios.post(
                                `https://together-v.com/api/v1/agent/updateAgentByAdmin/${params.di}`,
                                updatedData,
                                {
                                  withCredentials: true,
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                  },
                                }
                              );

                              if (
                                data.message === "Agent updated successfully"
                              ) {
                                toast.success("Profile updated successfully");
                              }
                            } catch (err) {
                              toast.error(
                                err.response?.data?.message ||
                                "An error occurred"
                              );
                            }
                          }}
                        >
                          <div
                            className="tab-pane fade active show"
                            id="all-booking"
                            role="tabpanel"
                          >
                            {isLoading ? (
                              <div className="'w-100 py-5 d-flex justify-content-center">
                                <BallTriangle
                                  height={100}
                                  width={100}
                                  radius={5}
                                  color="#4fa94d"
                                  ariaLabel="ball-triangle-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <>
                                <div className="row mb-3">
                                  <label
                                    htmlFor="firstName"
                                    className="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("firstName")}
                                  </label>
                                  <div className="col-md-8 col-lg-9">
                                    <input
                                      name="firstName"
                                      type="text"
                                      className="form-control"
                                      id="firstName"
                                      defaultValue={data?.data?.firstName || ""}
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="secondName"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("secondName")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="secondName"
                                      type="text"
                                      class="form-control"
                                      id="secondName"
                                      defaultValue={
                                        data?.data?.secondName || ""
                                      }
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="thirdName"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("thirdName")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="thirdName"
                                      type="text"
                                      class="form-control"
                                      id="thirdName"
                                      defaultValue={data?.data?.thirdName || ""}
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="lastName"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("lastName")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="lastName"
                                      type="text"
                                      class="form-control"
                                      id="lastName"
                                      defaultValue={data?.data?.lastName || ""}
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="country"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("country")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="country"
                                      type="text"
                                      class="form-control"
                                      id="country"
                                      defaultValue={data?.data?.country || ""}
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="phone"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("phone")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="phone"
                                      type="text"
                                      class="form-control"
                                      id="phone"
                                      defaultValue={data?.data?.phone || ""}
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="email"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("email")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="email"
                                      type="email"
                                      class="form-control"
                                      id="email"
                                      defaultValue={data?.data?.email || ""}
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="nationalId"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("nationalId")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="nationalId"
                                      type="text"
                                      class="form-control"
                                      id="nationalId"
                                      defaultValue={
                                        data?.data?.nationalId || ""
                                      }
                                    />
                                  </div>
                                </div>

                                <div class="row mb-3">
                                  <label
                                    for="address"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("address")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="address"
                                      type="text"
                                      class="form-control"
                                      id="address"
                                      defaultValue={data?.data?.address || ""}
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="governorate"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("governorate")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="governorate"
                                      type="text"
                                      class="form-control"
                                      id="governorate"
                                      defaultValue={
                                        data?.data?.governorate || ""
                                      }
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="city"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("city")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="city"
                                      type="text"
                                      class="form-control"
                                      id="city"
                                      defaultValue={data?.data?.city || ""}
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="inheritorName"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("inheritorName")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="inheritorName"
                                      type="text"
                                      class="form-control"
                                      id="inheritorName"
                                      defaultValue={
                                        data?.data?.inheritorName || ""
                                      }
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="grandfatherInheritorName"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("motherInheritorName")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="grandfatherInheritorName"
                                      type="text"
                                      class="form-control"
                                      id="grandfatherInheritorName"
                                      defaultValue={
                                        data?.data?.grandfatherInheritorName ||
                                        ""
                                      }
                                    />
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <label
                                    for="zipCode"
                                    class="col-md-4 col-lg-3 col-form-label"
                                  >
                                    {t("zipCode")}
                                  </label>
                                  <div class="col-md-8 col-lg-9">
                                    <input
                                      name="zipCode"
                                      type="text"
                                      class="form-control"
                                      id="zipCode"
                                      defaultValue={data?.data?.zipCode || ""}
                                    />
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    className="button dark-1 bg-blue-1 md text-white"
                                  >
                                    {t("saveChanges")}
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </form>
                      </div>

                      <div
                        className="tab-pane fade profile-edit pt-3"
                        id="profile-edit"
                        role="tabpanel"
                        aria-labelledby="#edit-tap"
                      >
                        <div class="row mb-3">
                          <label
                            for="balance"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            {t("balance")}
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              name="balance"
                              type="text"
                              class="form-control"
                              id="balance"
                              defaultValue={data?.data?.balance || ""}
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label
                            for="commission"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            {t("commission")}
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              name="commission"
                              type="text"
                              class="form-control"
                              id="commission"
                              defaultValue={data?.data?.commission || ""}
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label
                            for="pv"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            {t("pv")}
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              name="pv"
                              type="text"
                              class="form-control"
                              id="pv"
                              defaultValue={data?.data?.pv || ""}
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label
                            for="commissionStatus"
                            class="col-md-4 col-lg-3 col-form-label"
                          >
                            {t("commissionStatus")}
                          </label>
                          <div class="col-md-8 col-lg-9">
                            <input
                              name="commissionStatus"
                              type="text"
                              class="form-control"
                              id="commissionStatus"
                              defaultValue={data?.data?.commissionStatus || ""}
                            />
                          </div>
                        </div>
                        {/* Add Balance */}
                        {/* Add Balance */}
                        <div className="row mb-3">
                          <label
                            htmlFor="addBalance"
                            className={`col-md-4 col-lg-3 col-form-label ${i18n.language === "ar" ? "text-end" : "text-start"}`}
                          >
                            {t("addBalance")}
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="addBalance"
                              type="number"
                              className="form-control"
                              id="addBalance"
                              placeholder={t("enterAmountToAdd")}
                              onChange={(e) => setAddValue(e.target.value)}
                            />
                            <button
                              className="btn btn-success mt-2"
                              onClick={handleAddBalance}
                              disabled={loadingAddBalance}
                            >
                              {loadingAddBalance ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              ) : (
                                t("addBalance")
                              )}
                            </button>
                          </div>
                        </div>

                        {/* Remove Balance */}
                        <div className="row mb-3">
                          <label
                            htmlFor="removeBalance"
                            className={`col-md-4 col-lg-3 col-form-label ${i18n.language === "ar" ? "text-end" : "text-start"}`}
                          >
                            {t("removeBalance")}
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="removeBalance"
                              type="number"
                              className="form-control"
                              id="removeBalance"
                              placeholder={t("enterAmountToRemove")}
                              onChange={(e) => setRemoveValue(e.target.value)}
                            />
                            <button
                              className="btn btn-danger mt-2"
                              onClick={handleRemoveBalance}
                              disabled={loadingRemoveBalance}
                            >
                              {loadingRemoveBalance ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              ) : (
                                t("removeBalance")
                              )}
                            </button>
                          </div>
                        </div>

                        {/* Add PV */}
                        <div className="row mb-3">
                          <label
                            htmlFor="addPV"
                            className={`col-md-4 col-lg-3 col-form-label ${i18n.language === "ar" ? "text-end" : "text-start"}`}
                          >
                            {t("addPV")}
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="addPV"
                              type="number"
                              className="form-control"
                              id="addPV"
                              placeholder={t("enterPvToAdd")}
                              onChange={(e) => setAddPVValue(e.target.value)}
                            />
                            <button
                              className="btn btn-success mt-2"
                              onClick={handleAddPv}
                              disabled={loadingAddPv}
                            >
                              {loadingAddPv ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              ) : (
                                t("addPV")
                              )}
                            </button>
                          </div>
                        </div>


                      </div>

                      <div
                        className="tab-pane fade profile-change-password pt-3"
                        id="profile-change-password"
                        role="tabpanel"
                        aria-labelledby="#change-password"
                      >
                        {" "}
                        <div className="mb-3">
                          <div className="row mb-3">
                            <label
                              htmlFor="changePassword"
                              className="col-md-4 col-lg-3 col-form-label"
                            >
                              {t("changePassword")}
                            </label>
                            <div className="col-md-8 col-lg-9">
                              <input
                                name="changePassword"
                                type="text"
                                className="form-control"
                                id="changePassword"
                                placeholder={t("enterNewPassword")}
                                onChange={(e) => setNewPass(e.target.value)}
                              />
                              <button
                                className="btn btn-success mt-2"
                                onClick={handleNewPassword}
                              >
                                {t("changePassword")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade profile-change-password pt-3"
                        id="profile-change-eWallet"
                        role="tabpanel"
                        aria-labelledby="#change-eWallet"
                      >
                        {" "}
                        <div className="mb-3">
                          <div className="row mb-3">
                            <label
                              htmlFor="passwordEwallet"
                              className="col-md-4 col-lg-3 col-form-label"
                            >
                              {t("changeEWalletPassword")}
                            </label>
                            <div className="col-md-8 col-lg-9">
                              <input
                                name="passwordEwallet"
                                type="text"
                                className="form-control"
                                id="passwordEwallet"
                                placeholder={t("enterNewEWalletPassword")}
                                onChange={(e) =>
                                  setNewPassEWallet(e.target.value)
                                }
                              />
                              <button
                                className="btn btn-success mt-2"
                                onClick={handleNewPasswordEWallet}
                              >
                                {t("changePassword")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade profile-change-password pt-3"
                        id="profile-agent-tree"
                        role="tabpanel"
                        aria-labelledby="#agent-tree"
                      >
                        <div className="mb-3">
                          <div className="body genealogy-body genealogy-scroll">
                            <div
                              className="tab-pane fade active show"
                              id="all-booking"
                              role="tabpanel"
                            >
                              <div className="notice-container">
                                <div className="notice-icons">
                                  <div className="text-center mt-3 mb-4 me-2">

                                  </div>
                                  <div className="icon">
                                    <div className="circle bronze"></div>
                                    <span>{t("bronze")}</span>
                                  </div>
                                  <div className="icon">
                                    <div className="circle silver"></div>
                                    <span>{t("silver")}</span>
                                  </div>
                                  <div className="icon">
                                    <div className="circle gold"></div>
                                    <span>{t("gold")}</span>
                                  </div>
                                  <div className="icon">
                                    <div className="circle red"></div>
                                    <span>{t("inactive")}</span>
                                  </div>
                                </div>
                              </div>
                              <h4 className="text-center mt-3">
                                {t("agentTree")}
                              </h4>
                              {isTreeLoading ? (
                                <div className="w-100 py-5 d-flex justify-content-center">
                                  <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#4fa94d"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                  />
                                </div>
                              ) : (
                                <div className="genealogy-tree">
                                  <ul>
                                    {filteredTree
                                      ? renderFixedTree(filteredTree) // Correct function name here
                                      : treeData?.data?.data && renderFixedTree(treeData.data.data)}
                                  </ul>
                                </div>
                              )}
                              <Modal show={!!selectedMember} onHide={() => setSelectedMember(null)}>
                                <Modal.Header closeButton>
                                  <Modal.Title>{t("member_details")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {selectedMember ? (
                                    <div>
                                      <p>
                                        <img src={selectedMember.profileImage} alt="" />
                                      </p>
                                      <p>
                                        <strong>{t("level")}:</strong> {selectedMember.level}
                                      </p>
                                      <p>
                                        <strong>{t("id")}:</strong> {selectedMember.id}
                                      </p>
                                      <p>
                                        <strong>{t("name")}:</strong> {selectedMember.firstName}{" "}
                                        {selectedMember.secondName} {selectedMember.thirdName}{" "}
                                        {selectedMember.lastName}
                                      </p>
                                      <p>
                                        <strong>{t("joined")}:</strong> {selectedMember.createdAt.split("T")[0]}
                                      </p>
                                      <p>
                                        <strong>{t("pv")}:</strong> {selectedMember.pv}
                                      </p>
                                      <p>
                                        <strong>{t("left_pv")}:</strong> {selectedMember.leftPv}
                                      </p>
                                      <p>
                                        <strong>{t("right_pv")}:</strong> {selectedMember.rightPv}
                                      </p>
                                      <p>
                                        <strong>{t("carry_left")}:</strong> {selectedMember.leftCounter}
                                      </p>
                                      <p>
                                        <strong>{t("carry_right")}:</strong> {selectedMember.rightCounter}
                                      </p>
                                    </div>
                                  ) : (
                                    <p>{t("loading")}...</p>
                                  )}
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="secondary" onClick={() => setSelectedMember(null)}>
                                    {t("close")}
                                  </Button>
                                </Modal.Footer>
                              </Modal>

                              <Modal show={!!selectedMemberLeft} onHide={() => setSelectedMemberLeft(null)}>
                                <Modal.Header closeButton>
                                  <Modal.Title>{t("member_details")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {selectedMemberLeft ? (
                                    <div>
                                      <p>
                                        <img src={selectedMemberLeft.profileImage} alt="" />
                                      </p>
                                      <p>
                                        <strong>{t("level")}:</strong> {selectedMemberLeft.level}
                                      </p>
                                      <p>
                                        <strong>{t("id")}:</strong> {selectedMemberLeft.id}
                                      </p>
                                      <p>
                                        <strong>{t("name")}:</strong> {selectedMemberLeft.firstName}{" "}
                                        {selectedMemberLeft.secondName} {selectedMemberLeft.thirdName}{" "}
                                        {selectedMemberLeft.lastName}
                                      </p>
                                      <p>
                                        <strong>{t("joined")} :</strong> {selectedMemberLeft.createdAt.split("T")[0]}
                                      </p>
                                      <p>
                                        <strong>{t("pv_02")} :</strong> {selectedMemberLeft.pv >= 2000 ? 1000 : 0}
                                      </p>
                                      <p>
                                        <strong>{t("left_pv_02")} :</strong> {selectedMemberLeft.leftPv2}
                                      </p>
                                      <p>
                                        <strong>{t("right_pv_02")} :</strong> {selectedMemberLeft.rightPv2}
                                      </p>
                                      <p>
                                        <strong>{t("carry_left_02")} :</strong> {selectedMemberLeft.leftCounter2}
                                      </p>
                                      <p>
                                        <strong>{t("carry_right_02")} :</strong> {selectedMemberLeft.rightCounter2}
                                      </p>
                                    </div>
                                  ) : (
                                    <p>
                                      {t("loading")}...
                                    </p>

                                  )}
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="secondary" onClick={() => setSelectedMemberLeft(null)}>
                                    {t("close")}
                                  </Button>
                                </Modal.Footer>
                              </Modal>


                              <Modal show={!!selectedMemberRight} onHide={() => setSelectedMemberRight(null)}>
                                <Modal.Header closeButton>
                                  <Modal.Title>{t("member_details")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {selectedMemberRight ? (
                                    <div>
                                      <p>
                                        <img src={selectedMemberRight.profileImage} alt="" />
                                      </p>
                                      <p>
                                        <strong>{t("level")}:</strong> {selectedMemberRight.level}
                                      </p>
                                      <p>
                                        <strong>{t("id")}:</strong> {selectedMemberRight.id}
                                      </p>
                                      <p>
                                        <strong>{t("name")}:</strong> {selectedMemberRight.firstName}{" "}
                                        {selectedMemberRight.secondName} {selectedMemberRight.thirdName}{" "}
                                        {selectedMemberRight.lastName}
                                      </p>
                                      <p>
                                        <strong>{t("joined")}:</strong> {selectedMemberRight.createdAt.split("T")[0]}
                                      </p>
                                      <p>
                                        <strong>{t("pv_03")}:</strong> {selectedMemberRight.pv >= 3000 ? 1000 : 0}
                                      </p>
                                      <p>
                                        <strong>{t("left_pv_03")}:</strong> {selectedMemberRight.leftPv3}
                                      </p>
                                      <p>
                                        <strong>{t("right_pv_03")}:</strong> {selectedMemberRight.rightPv3}
                                      </p>
                                      <p>
                                        <strong>{t("carry_left_03")}:</strong> {selectedMemberRight.leftCounter3}
                                      </p>
                                      <p>
                                        <strong>{t("carry_right_03")}:</strong> {selectedMemberRight.rightCounter3}
                                      </p>
                                    </div>
                                  ) : (
                                    <p>{t("loading")}...</p>
                                  )}
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="secondary" onClick={() => setSelectedMemberRight(null)}>
                                    {t("close")}
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default UpdateDataAgent;
