import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { AdminContext } from "../../Context/AdminContext"
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { useTranslation } from "react-i18next";


function Filtration() {
    let { logOut } = useContext(AdminContext)

    const [searchId, setSearchId] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filteredData, setFilteredData] = useState(null);
    const [error, setError] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();
    const { t, i18n } = useTranslation(); // Translation hook
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const handleSearch = async () => {
        if (!searchId || !startDate || !endDate) {
            setError("Please fill all fields");
            return;
        }

        try {
            const response = await axios.get(
                `https://together-v.com/api/v1/agent/AgentBalanceHistoryByAdmin/${searchId}`,
                {
                    params: {
                        startDate,
                        endDate,
                    },
                    withCredentials: true,
                }
            );
            setFilteredData(response.data.data);
            setError("");
        } catch (err) {
            setError("Failed to fetch data. Please check inputs.");
        }
    };

    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Filtration</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center justify-content-between w-100">
                    {/* Logo */}
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/DashboardAdmin")}
                        />
                    </div>

                    {/* Language Toggle Buttons */}
                    <div className="d-flex">
                        <li className="nav-item dropdown bg-body">
                            <Link
                                className="nav-link "
                                to="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <span>{t("language")}</span>
                            </Link>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <button
                                        className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                        onClick={() => changeLanguage("en")}
                                    >
                                        English
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                        onClick={() => changeLanguage("ar")}
                                    >
                                        العربية
                                    </button>
                                </li>

                            </ul>
                        </li>

                    </div>

                    {/* Sidebar Toggle */}
                    <h2 className="menu-icon" onClick={toggleSidebar}>
                        <FiAlignJustify />
                    </h2>
                </div>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>{t("allAgents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/NewId">
                            <i className="bi bi-grid"></i>
                            <span>{t("changeID")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addEpin")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>{t("ePinsHistory")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addProduct")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("allProducts")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("myTree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addEvents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ViewAllEventsAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("viewEvents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("withdrawRequests")}</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("myTransfers")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allPurchase")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allTransfers")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allWithdraws")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/BinaryReports">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("binaryDetails")}</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("filtration")}</span>
                        </Link>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>{t("allContact")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>{t("profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>{t("logout")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>



            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>{t("agentBalanceHistory")}</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">{t("home")}</Link></li>
                                <li className="breadcrumb-item">{t("dashboard")}</li>
                                <li className="breadcrumb-item active">{t("filtration")}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="p-4 rounded-1 bg-white shadow-3">
                        <div className="text-center mb-2">
                            <input
                                type="text"
                                placeholder="Enter Agent ID"
                                value={searchId}
                                onChange={(e) => setSearchId(e.target.value)}
                                className="form-control mb-3"
                            />
                            <div className="d-flex">
                                <div className="w-100 text-start me-2">
                                    <label htmlFor="Start Date" className="">{t("startDate")}</label>
                                    <input
                                        type="date"
                                        placeholder="Start Date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        className="form-control mb-3  me-2"
                                    />
                                </div>
                                <div className="w-100 text-start">
                                    <label htmlFor="End Date">{t("endDate")}</label>
                                    <input
                                        type="date"
                                        placeholder="End Date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        className="form-control mb-3 "
                                    />
                                </div>

                            </div>

                            <button
                                className="btn btn-primary mt-3"
                                onClick={handleSearch}
                            >
                                {t("search")}
                            </button>
                            {error && <p className="text-danger mt-2">{error}</p>}
                        </div>
                        <div className="tabs">
                            <div className="tab-content pt-4">
                                <div className="tab-pane fade active show" id="all-booking" role="tabpanel">
                                    <div className="overflow-scroll scroll-bar-1">
                                        <h2 className="text-center mt-2">{t("balanceHistory")}</h2>
                                        <table className="table col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>{t("id")}</th>
                                                    <th>{t("amount")}</th>
                                                    <th>{t("createdAt")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Array.isArray(filteredData?.balanceHistory) && filteredData?.balanceHistory.length > 0 ? (
                                                    filteredData?.balanceHistory.map((entry, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{entry.value}</td>
                                                            <td>{entry.createdAt.split("T")[0]}</td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4">{t("noDataAvailable")}</td>
                                                    </tr>
                                                )}


                                            </tbody>
                                        </table>

                                        <h2 className="text-center mt-4">{t("transferHistory")}</h2>
                                        <table className="table col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>{t("id")}</th>
                                                    <th>{t("from")}</th>
                                                    <th>{t("amount")}</th>
                                                    <th>{t("to")}</th>
                                                    <th>{t("createdAt")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Array.isArray(filteredData?.transferHistory
                                                ) && filteredData?.transferHistory
                                                    .length > 0 ? (
                                                    filteredData?.transferHistory
                                                        .map((entry, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{entry.from.firstName} {entry.from.lastName}</td>
                                                                <td>{entry.request}</td>
                                                                <td>{entry.to.firstName} {entry.to.lastName}</td>

                                                                <td>{entry.createdAt.split("T")[0]}</td>

                                                            </tr>
                                                        ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4">{t("noDataAvailable")}</td>
                                                    </tr>
                                                )}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default Filtration;
