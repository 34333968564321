import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import AgentContextProvider from './Context/AgentContext';
import AdminContextProvider from './Context/AdminContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import '../src/Language/i18n'; // Import i18n configuration

const root = ReactDOM.createRoot(document.getElementById('root'));
let queryClient = new QueryClient();

root.render(
    <QueryClientProvider client={queryClient}>
        <AdminContextProvider>
            <AgentContextProvider>
                <App />
            </AgentContextProvider>
        </AdminContextProvider>
    </QueryClientProvider>

);

reportWebVitals();
