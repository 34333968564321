import React from "react";
import { Helmet } from "react-helmet";
import logoo from "../../imges/logo.png";
import { Link, useNavigate } from "react-router-dom";




function BlockPage() {
    let navigate = useNavigate();

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                    <title>Block Page</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center ">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}

                        />
                    </div>

                </div>

            </header>
            <main>
                <div class="container">
                    <section
                        class="section min-vh-100 d-flex flex-column align-items-center justify-content-center"
                    >
                        <h1></h1>
                        <h2 class="text-center">You Were Blocked By Admin</h2>
                        <button class="btn btn-info mt-3" to="/login" onClick={() => navigate('/Dashboard')}>Back To Login</button>
                        
                    </section>
                </div>
            </main>



        </>

    );
}

export default BlockPage;
