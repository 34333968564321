import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { AdminContext } from "../../Context/AdminContext";
import { jsPDF } from "jspdf";
import logoo from "../../imges/logo.png";
import { FiAlignJustify } from "react-icons/fi";
import gold from "../../imges/gold.jpg";
import red from "../../imges/red.png";
import silver from "../../imges/silver.avif";
import bronze from "../../imges/bronze.jpg";
import { BallTriangle } from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
function MyTreeAdmin() {
  let { logOut } = useContext(AdminContext);
  const [visibility, setVisibility] = useState({});
  const [selectedMember, setSelectedMember] = useState(null); // For modal
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
  const navigate = useNavigate();
  const [searchId, setSearchId] = useState(""); // Search input value
  const [filteredTree, setFilteredTree] = useState(null); // State for filtered tree
  const [isSearching, setIsSearching] = useState(false); // Search loading state
  const [loadingParent, setLoadingParent] = useState(false);
  const [selectedMemberLeft, setSelectedMemberLeft] = useState(null); // For modal
  const [selectedMemberRight, setSelectedMemberRight] = useState(null); // For modal
  const { t, i18n } = useTranslation(); // Translation hook
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Fetch tree data
  function getAgentTree() {
    return axios.get(`https://together-v.com/api/v1/agent/getAgentTree`, {
      withCredentials: true,
    });
  }

  const { data, isLoading } = useQuery("getAgentTree", getAgentTree);



  const fetchMemberDetails = async (memberId) => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/getAgentByAdmin/${memberId}`,
        { withCredentials: true }
      );
      setSelectedMember(response.data.data);

      // Log the fetched agent details to the console
      console.log("Agent Details:", response.data.data);
    } catch (error) {
      toast.error("Failed to fetch member details.");
      console.error("Error fetching member details:", error);
    }
  };

  const fetchAgentTreeById = async () => {
    if (!searchId.trim()) {
      toast.error("Please enter a valid ID.");
      return;
    }
    setIsSearching(true);
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/getAgentTreeForAdmin/${searchId}`,
        { withCredentials: true }
      );
      setFilteredTree(response.data.data);
      console.log("Agentt Details:", response.data.data);

    } catch (error) {
      toast.error("Failed to fetch tree for the provided ID.");
    } finally {
      setIsSearching(false);
    }
  };

  const fetchAdditionalData = async (memberId) => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/getAgentByAdmin/${memberId}`,
        { withCredentials: true }
      );
      setSelectedMemberLeft(response.data.data);
      console.log("Agenttt Details:", response.data.data);

      // Log the fetched agent details to the console
    } catch (error) {
      toast.error("Failed to fetch member details.");
      console.error("Error fetching member details:", error);
    }
  };

  const fetchAdditionalDataRight = async (memberId) => {
    try {
      const response = await axios.get(
        `https://together-v.com/api/v1/agent/getAgentByAdmin/${memberId}`,
        { withCredentials: true }
      );
      setSelectedMemberRight(response.data.data);

      // Log the fetched agent details to the console
    } catch (error) {
      toast.error("Failed to fetch member details.");
      console.error("Error fetching member details:", error);
    }
  };
  const renderFixedTree = (data) => {
    const getImageByLevel = (level) => {
      switch (level) {
        case "red":
          return red;
        case "gold":
          return gold;
        case "silver":
          return silver;
        case "bronze":
          return bronze;
        default:
          return "assets/img/default.jpg";
      }
    };
    const navigateToAddAgent = (parentId, position) => {
      navigate(`/addagentadmin?parentId=${parentId}&position=${position}`);
    };

    return (
      <ul style={{ listStyleType: "none", textAlign: "center", width: "100%" }} className="mt-3 mb-5">
        {/* Root Node */}
        <li>

          <div className="member-view-boxx" style={{
            textAlign: "center",
            marginBottom: "10px",
            width: "100%",
          }}>
            <div
              className="member-image mt-4"
              style={{
                backgroundColor: "#31373a",
                width: "25%",
                height: "100px", // Adjusted height for better visibility
                borderRadius: "10px",
                margin: "auto"
              }}
            >
              <img
                src={
                  data?.level === "bronze"
                    ? bronze
                    : data?.level === "silver"
                      ? silver
                      : data?.level === "gold"
                        ? gold
                        : data?.level === "red"
                          ? red
                          : "assets/img/default.jpg" // Fallback if no level matches
                }
                alt={data?.level || "default"}
                style={{
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  cursor: "pointer",
                }}
                onClick={async () => {
                  if (filteredTree && filteredTree.parent !== null) {
                    setLoadingParent(true); // Start loading
                    try {
                      // Fetch parent data based on filteredTree.parent
                      const response = await axios.get(
                        `https://together-v.com/api/v1/agent/getAgentTreeForAdmin/${filteredTree.parent.id}`,
                        { withCredentials: true }
                      );
                      setFilteredTree(response.data.data); // Update the filteredTree with the fetched parent data
                    } catch (error) {
                      console.error("Failed to fetch parent tree data:", error);
                      toast.error("Failed to fetch parent data.");
                      console.log(error)
                    } finally {
                      setLoadingParent(false); // Stop loading
                    }
                  } else if (data?.parent !== null) {
                    navigate(`/treeDetails/${data?.parent.id}`);
                  } else {
                    // Handle the case where no valid parent exists
                    console.warn("Parent data is invalid or not available.");
                    toast.error("this is the root.");
                  }
                }}
              />


              <h3 onClick={() => fetchMemberDetails(data?.id)} className="fnt-10px" style={{ color: "white", marginTop: "10px", cursor: "pointer" }}>
                01: {data?.id}
              </h3>
            </div>


            {/* First Level: 02 and 03 */}
            <ul style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <li style={{ margin: "0 20px" }}>

                <div className="member-view-boxx" style={{
                  textAlign: "center",
                  marginBottom: "10px",
                  width: "100%",
                }}>
                  <div
                    className="member-image mt-4"
                    style={{
                      backgroundColor: "#31373a",
                      width: "50%",
                      height: "100px", // Adjusted height for better visibility
                      borderRadius: "10px",
                      margin: "auto"
                    }}
                  >
                    <img
                      src={
                        data?.level === "bronze"
                          ? red
                          : data?.level === "silver"
                            ? silver
                            : data?.level === "gold"
                              ? gold
                              : data?.level === "red"
                                ? red
                                : "assets/img/default.jpg" // Fallback if no level matches
                      }
                      alt={data?.level || "default"}
                      style={{ borderRadius: "50%", width: "50px", height: "50px" }}
                    />
                    <h3 onClick={() => fetchAdditionalData(data?.id)} className="fnt-10px" style={{ color: "white", marginTop: "10px", cursor: "pointer" }}>
                      02: {data?.id}
                    </h3>
                  </div>
                </div>

                {/* Children of 02 */}
                <ul style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                  <li style={{ margin: "0 20px" }}>
                    <div className="member-view-boxx">
                      <div
                        className="member-image my-4"
                        style={{
                          backgroundColor: "#31373a",
                          width: "100%",
                          height: "100px",
                          borderRadius: "10px",
                          margin: "auto"
                        }}
                      >
                        {data?.leftChild0 ? (
                          <>
                            <img
                              src={getImageByLevel(data?.leftChild0?.level)}
                              alt="child1"
                              style={{ borderRadius: "50%", width: "50px", height: "50px", cursor: "pointer" }}

                              onClick={() => {
                                if (filteredTree !== null) {
                                  setFilteredTree(data?.leftChild0)
                                } else {
                                  navigate(`/treeDetails/${data?.leftChild0?.id}`);
                                }
                              }}

                            />
                            <h3
                              onClick={() => fetchMemberDetails(data?.leftChild0?.id)}
                              className="fnt-10px"
                              style={{ color: "white", marginTop: "10px", cursor: "pointer" }}
                            >
                              01: {data?.leftChild0?.id}
                            </h3>
                          </>
                        ) : (
                          // "+" Button only shown when leftChild0 does not exist
                          <button
                            className="btn btn-primary btn-sm"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%"
                            }}
                            onClick={() => navigateToAddAgent(data?.id, "left0")}
                          >
                            +
                          </button>
                        )}
                      </div>
                    </div>
                  </li>

                  <li style={{ margin: "0 20px" }}>
                    <div className="member-view-boxx">
                      <div
                        className="member-image my-4"
                        style={{
                          backgroundColor: "#31373a",
                          width: "100%",
                          height: "100px",
                          borderRadius: "10px",
                          margin: "auto"
                        }}
                      >
                        {data?.rightChild0 ? (
                          <>
                            <img
                              src={getImageByLevel(data?.rightChild0?.level)}
                              alt="child2"
                              style={{ borderRadius: "50%", width: "50px", height: "50px", cursor: "pointer" }}
                              onClick={() => {
                                if (filteredTree !== null) {
                                  setFilteredTree(data?.rightChild0)
                                } else {
                                  navigate(`/treeDetails/${data?.rightChild0?.id}`);
                                }
                              }}
                            />
                            <h3
                              onClick={() => fetchMemberDetails(data?.rightChild0?.id)}
                              className="fnt-10px"
                              style={{ color: "white", marginTop: "10px", cursor: "pointer" }}
                            >
                              01: {data?.rightChild0?.id}
                            </h3>
                          </>
                        ) : (
                          // "+" Button only shown when rightChild0 does not exist
                          <button
                            className="btn btn-primary btn-sm"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%"
                            }}
                            onClick={() => navigateToAddAgent(data?.id, "right0")}
                          >
                            +
                          </button>
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              </li>

              <li style={{ margin: "0 20px" }}>
                <div className="member-view-boxx">
                  <div
                    className="member-image mt-4"
                    style={{
                      backgroundColor: "#31373a",
                      width: "100%",
                      height: "100px", // Adjusted height for better visibility
                      borderRadius: "10px",
                      margin: "auto"
                    }}
                  >

                    <img
                      src={
                        data?.level === "bronze"
                          ? red
                          : data?.level === "silver"
                            ? red
                            : data?.level === "gold"
                              ? gold
                              : data?.level === "red"
                                ? red
                                : "assets/img/default.jpg" // Fallback if no level matches
                      }
                      alt={data?.level || "default"}
                      style={{ borderRadius: "50%", width: "50px", height: "50px" }}
                    />
                    <h3 onClick={() => fetchAdditionalDataRight(data?.id)} className="fnt-10px" style={{ color: "white", marginTop: "10px", cursor: "pointer" }}>
                      03: {data?.id}
                    </h3>
                  </div>
                </div>

                {/* Children of 03 */}
                <ul style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                  <li style={{ margin: "0 20px" }}>
                    <div className="member-view-boxx">
                      <div
                        className="member-image mt-4"
                        style={{
                          backgroundColor: "#31373a",
                          width: "100%",
                          height: "100px", // Adjusted height for better visibility
                          borderRadius: "10px",
                          margin: "auto"
                        }}
                      >
                        {data?.leftChild1 ? (<>
                          <img
                            src={getImageByLevel(data?.leftChild1?.level)}
                            alt="child3"
                            style={{ borderRadius: "50%", width: "50px", height: "50px", cursor: "pointer" }}
                            onClick={() => {
                              if (filteredTree !== null) {
                                setFilteredTree(data?.leftChild1)
                              } else {
                                navigate(`/treeDetails/${data?.leftChild1?.id}`);
                              }
                            }}
                          />
                          <h3 onClick={() => fetchMemberDetails(data?.leftChild1?.id)} className="fnt-10px" style={{ color: "white", marginTop: "10px", cursor: "pointer" }}>
                            01: {data?.leftChild1?.id}
                          </h3>
                        </>) : (
                          // "+" Button only shown when rightChild0 does not exist
                          <button
                            className="btn btn-primary btn-sm"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%"
                            }}
                            onClick={() => navigateToAddAgent(data?.id, "left1")}
                          >
                            +
                          </button>
                        )}

                      </div>
                    </div>
                  </li>
                  <li style={{ margin: "0 20px" }}>
                    <div className="member-view-boxx">
                      <div
                        className="member-image mt-4"
                        style={{
                          backgroundColor: "#31373a",
                          width: "100%",
                          height: "100px", // Adjusted height for better visibility
                          borderRadius: "10px",
                          margin: "auto"
                        }}
                      >
                        {data?.rightChild1 ? (<>
                          <img
                            src={getImageByLevel(data?.rightChild1?.level)}
                            alt="child4"
                            style={{ borderRadius: "50%", width: "50px", height: "50px", cursor: "pointer" }}
                            onClick={() => {
                              if (filteredTree !== null) {
                                // Set the filteredTree with the current data to show filtered results
                                setFilteredTree(data?.rightChild1);
                              } else if (data?.rightChild1?.id) {
                                // Navigate to the right child if it exists
                                navigate(`/treeDetails/${data?.rightChild1?.id}`);
                              }
                            }}

                          />
                          <h3 onClick={() => fetchMemberDetails(data?.rightChild1?.id)} className="fnt-10px" style={{ color: "white", marginTop: "10px", cursor: "pointer" }}>
                            01: {data?.rightChild1?.id}
                          </h3>
                        </>) : (
                          // "+" Button only shown when rightChild0 does not exist
                          <button
                            className="btn btn-primary btn-sm"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%"
                            }}
                            onClick={() => navigateToAddAgent(data?.id, "right1")}
                          >
                            +
                          </button>
                        )}

                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    );
  };

  useEffect(() => {
    if (i18n.language === "ar") {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }

    // Cleanup on unmount
    return () => {
      document.body.classList.remove("rtl");
    };
  }, [i18n.language]);


  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            content="width=device-width, initial-scale=1.0"
            name="viewport"
          />
          <title>MY Tree</title>
          <meta content="" name="description" />
          <meta content="" name="keywords" />
        </Helmet>
        <div className="d-flex align-items-center justify-content-between w-100">
          {/* Logo */}
          <div>
            <img
              src={logoo}
              alt="Logo"
              className="logo d-flex align-items-center w-35"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/DashboardAdmin")}
            />
          </div>

          {/* Language Toggle Buttons */}
          <div className="d-flex">
            <li className="nav-item dropdown bg-body">
              <Link
                className="nav-link "
                to="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{t("language")}</span>
              </Link>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <button
                    className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                    onClick={() => changeLanguage("ar")}
                  >
                    العربية
                  </button>
                </li>

              </ul>
            </li>

          </div>

          {/* Sidebar Toggle */}
          <h2 className="menu-icon" onClick={toggleSidebar}>
            <FiAlignJustify />
          </h2>
        </div>
      </header>

      <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link" to="/DashboardAdmin">
              <i className="bi bi-grid"></i>
              <span>{t("dashboard")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/Agents">
              <i className="bi bi-grid"></i>
              <span>{t("allAgents")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/NewId">
              <i className="bi bi-grid"></i>
              <span>{t("changeID")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/AddEpin">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("addEpin")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/EPin">
              <i className="bi bi-grid"></i>
              <span>E-Pins</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/EpinHistory">
              <i className="bi bi-grid"></i>
              <span>{t("ePinsHistory")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/AddProduct">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("addProduct")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/AllProducts">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("allProducts")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/MyTreeAdmin">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("myTree")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/AddEvent">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("addEvents")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/ViewAllEventsAdmin">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("viewEvents")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/WithDrawRequest">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("withdrawRequests")}</span>
            </Link>
          </li>

          <li className="nav-item dropdown bg-body">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("reports")}</span>
            </Link>
            <ul className="dropdown-menu">
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/MyTransfers">
                  <i className="bi bi-box-arrow-in-down"></i>
                  <span>{t("myTransfers")}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                  <i className="bi bi-box-arrow-in-down"></i>
                  <span>{t("allPurchase")}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/TransferRequests">
                  <i className="bi bi-box-arrow-in-down"></i>
                  <span>{t("allTransfers")}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                  <i className="bi bi-box-arrow-in-down"></i>
                  <span>{t("allWithdraws")}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link collapsed" to="/BinaryReports">
                  <i className="bi bi-box-arrow-in-down"></i>
                  <span>{t("binaryDetails")}</span>
                </Link>
              </li>
            </ul>
          </li>


          <li className="nav-item">
            <Link className="nav-link collapsed" to="/Filtration">
              <i className="bi bi-box-arrow-in-down"></i>
              <span>{t("filtration")}</span>
            </Link>
          </li>
          <li className="nav-heading">{t("pages")}</li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/AllContactUs">
              <i className="bi bi-person"></i>
              <span>{t("allContact")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/ProfileAdmin">
              <i className="bi bi-person"></i>
              <span>{t("profile")}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" onClick={() => logOut()}>
              <i className="bi bi-person"></i>
              <span>{t("logout")}</span>
            </Link>
          </li>
        </ul>
      </aside>


      <section>
        <div id="main" className={`bg-light-2 ${i18n.language === "ar" ? "text-end" : "text-start"}`}>
          <div className="pagetitle">
            <h1>{t("my_tree")}</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboardadmin">{t("home")}</Link>
                </li>
                <li className="breadcrumb-item">{t("dashboard")}</li>
                <li className="breadcrumb-item active">{t("tree")}</li>
              </ol>
            </nav>
          </div>
          <div className="notice-container">
            <div className="notice-icons">
              <div className="text-center mt-3 mb-4 me-2">
                <input
                  type="text"
                  className="form-control d-inline-block w-100 "
                  placeholder={t("enter_id_to_search")}
                  value={searchId}
                  onChange={(e) => setSearchId(e.target.value)}
                />
                <button
                  className="btn btn-primary mt-2"
                  onClick={fetchAgentTreeById}
                  disabled={isSearching}
                >
                  {isSearching ? t("searching") : t("search")}
                </button>
              </div>
              <div className="icon">
                <div className="circle bronze"></div>
                <span>{t("bronze")}</span>
              </div>
              <div className="icon">
                <div className="circle silver"></div>
                <span>{t("silver")}</span>
              </div>
              <div className="icon">
                <div className="circle gold"></div>
                <span>{t("gold")}</span>
              </div>
              <div className="icon">
                <div className="circle red"></div>
                <span>{t("inactive")}</span>
              </div>
            </div>
          </div>
          <h4 className="text-center mt-3">{t("the_agent_tree")}:</h4>
          <div className="body genealogy-body genealogy-scroll">
            {isLoading ? (
              <div className="'w-100 py-5 d-flex justify-content-center">
                <BallTriangle
                  height={100}
                  width={100}
                  radius={5}
                  color="#4fa94d"
                  ariaLabel="ball-triangle-loading"
                  visible={true}
                />
              </div>
            ) : (
              <div className="genealogy-tree">
                <ul key={filteredTree ? "filteredTree" : "defaultTree"}>
                  <div>
                    {loadingParent ? (
                      <div className="w-100 py-5 d-flex justify-content-center">
                        <BallTriangle
                          height={100}
                          width={100}
                          radius={5}
                          color="#4fa94d"
                          ariaLabel="ball-triangle-loading"
                          visible={true}
                        />
                      </div>
                    ) : (
                      renderFixedTree(filteredTree || data?.data?.data)
                    )}
                  </div>

                </ul>
              </div>


            )}
          </div>
        </div>
      </section>

      {/* Modal to show member details */}
      <Modal show={!!selectedMember} onHide={() => setSelectedMember(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("member_details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMember ? (
            <div>
              <p>
                <img src={selectedMember.profileImage} alt="" />
              </p>
              <p>
                <strong>{t("level")}:</strong> {selectedMember.level}
              </p>
              <p>
                <strong>{t("id")}:</strong> {selectedMember.id}
              </p>
              <p>
                <strong>{t("name")}:</strong> {selectedMember.firstName}{" "}
                {selectedMember.secondName} {selectedMember.thirdName}{" "}
                {selectedMember.lastName}
              </p>
              <p>
                <strong>{t("joined")}:</strong> {selectedMember.createdAt.split("T")[0]}
              </p>
              <p>
                <strong>{t("pv")}:</strong> {selectedMember.pv}
              </p>
              <p>
                <strong>{t("left_pv")}:</strong> {selectedMember.leftPv}
              </p>
              <p>
                <strong>{t("right_pv")}:</strong> {selectedMember.rightPv}
              </p>
              <p>
                <strong>{t("carry_left")}:</strong> {selectedMember.leftCounter}
              </p>
              <p>
                <strong>{t("carry_right")}:</strong> {selectedMember.rightCounter}
              </p>
            </div>
          ) : (
            <p>{t("loading")}...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedMember(null)}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={!!selectedMemberLeft} onHide={() => setSelectedMemberLeft(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("member_details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMemberLeft ? (
            <div>
              <p>
                <img src={selectedMemberLeft.profileImage} alt="" />
              </p>
              <p>
                <strong>{t("level")}:</strong> {selectedMemberLeft.level}
              </p>
              <p>
                <strong>{t("id")}:</strong> {selectedMemberLeft.id}
              </p>
              <p>
                <strong>{t("name")}:</strong> {selectedMemberLeft.firstName}{" "}
                {selectedMemberLeft.secondName} {selectedMemberLeft.thirdName}{" "}
                {selectedMemberLeft.lastName}
              </p>
              <p>
                <strong>{t("joined")} :</strong> {selectedMemberLeft.createdAt.split("T")[0]}
              </p>
              <p>
                <strong>{t("pv_02")} :</strong> {data?.data.data.pv >= 2000 ? 1000 : 0}
              </p>
              <p>
                <strong>{t("left_pv_02")} :</strong> {selectedMemberLeft.leftPv2}
              </p>
              <p>
                <strong>{t("right_pv_02")} :</strong> {selectedMemberLeft.rightPv2}
              </p>
              <p>
                <strong>{t("carry_left_02")} :</strong> {selectedMemberLeft.leftCounter2}
              </p>
              <p>
                <strong>{t("carry_right_02")} :</strong> {selectedMemberLeft.rightCounter2}
              </p>
            </div>
          ) : (
            <p>
              {t("loading")}...
            </p>

          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedMemberLeft(null)}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={!!selectedMemberRight} onHide={() => setSelectedMemberRight(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("member_details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMemberRight ? (
            <div>
              <p>
                <img src={selectedMemberRight.profileImage} alt="" />
              </p>
              <p>
                <strong>{t("level")}:</strong> {selectedMemberRight.level}
              </p>
              <p>
                <strong>{t("id")}:</strong> {selectedMemberRight.id}
              </p>
              <p>
                <strong>{t("name")}:</strong> {selectedMemberRight.firstName}{" "}
                {selectedMemberRight.secondName} {selectedMemberRight.thirdName}{" "}
                {selectedMemberRight.lastName}
              </p>
              <p>
                <strong>{t("joined")}:</strong> {selectedMemberRight.createdAt.split("T")[0]}
              </p>
              <p>
                <strong>{t("pv_03")}:</strong> {data?.data.data.pv >= 3000 ? 1000 : 0}
              </p>
              <p>
                <strong>{t("left_pv_03")}:</strong> {selectedMemberRight.leftPv3}
              </p>
              <p>
                <strong>{t("right_pv_03")}:</strong> {selectedMemberRight.rightPv3}
              </p>
              <p>
                <strong>{t("carry_left_03")}:</strong> {selectedMemberRight.leftCounter3}
              </p>
              <p>
                <strong>{t("carry_right_03")}:</strong> {selectedMemberRight.rightCounter3}
              </p>
            </div>
          ) : (
            <p>{t("loading")}...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedMemberRight(null)}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MyTreeAdmin;
