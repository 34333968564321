import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext"
import logoo from "../../imges/logo.png"
import { BallTriangle } from 'react-loader-spinner'
import { IoIosSettings } from "react-icons/io";

import { FiAlignJustify } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { CgProfile } from "react-icons/cg";

function BalanceHistoryAgent() {
    let { logOut } = useContext(AgentContext)
    let navigate = useNavigate();
    const recordsPerPage = 10; // Records per page
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    function agnetBalanceHistory() {
        return axios.get(`https://together-v.com/api/v1/agent/AgentBalanceHistory`, {
            withCredentials: true
        });
    }

    let { data, isLoading } = useQuery("agnetBalanceHistory", agnetBalanceHistory);
    const getStatusText = (status) => {
        if (status === "deposit") {
            return "Deposited By Admin";
        } else if (status === "product") {
            return "Bought Product";
        }
        else if (status === "transferIn") {
            return "Deposited";
        }
        else if (status === "transferOut") {
            return "Transfer Out";
        }
        else if (status === "direct_commission") {
            return "Direct Commission";
        }
        else {
            return "Withdrawn";
        }
    };
    console.log(data?.data.data)
    const records = data?.data?.data || [];
    const totalPages = Math.ceil(records.length / recordsPerPage);

    const paginatedData = records.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
    );

    const handlePageChange = (page) => setCurrentPage(page);
    const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    const handlePreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true
        });
    }

    let { data: prof } = useQuery("getCurrentAgent", getCurrentAgent);
    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                    <title>Balance History</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>

                </div>
                <div className="d-flex mx-auto">
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link "
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span>{t("language")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("en")}
                                >
                                    English
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("ar")}
                                >
                                    العربية
                                </button>
                            </li>
                        </ul>
                    </li>
                </div>


                <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">
                        <li class="nav-item dropdown ">
                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                {prof?.data.data.profileImage ? (
                                    <img
                                        src={prof?.data.data.profileImage}
                                        alt="Profile"
                                        className="rounded-circle"
                                    />
                                ) : (
                                    <CgProfile size={30} className="rounded-circle" />
                                )}                                <span class="d-none d-md-block dropdown-toggle ps-2">{prof?.data.data.firstName} {prof?.data.data.secondName}</span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>{t("my_profile")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>{t("sign_out")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <h2 className="menu-icon" onClick={toggleSidebar}>
                    <FiAlignJustify />
                </h2>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-tree"></i>
                            <span>{t("my_tree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-person-plus"></i>
                            <span>{t("add_agent")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box"></i>
                            <span>{t("all_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box"></i>
                            <span>{t("my_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-wallet2"></i>
                            <span>{t("my_wallet")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-arrow-left-right"></i>
                            <span>{t("transfers")}</span>
                        </Link>
                    </li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-file-earmark-text"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    {t("balance_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    {t("transfer_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    {t("withdraw_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/BinaryDetailsAgent">
                                    {t("binary_details")}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-cash"></i>
                            <span>{t("withdraw")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/profile">
                            <i className="bi bi-person"></i>
                            <span>{t("my_profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-calendar"></i>
                            <span>{t("all_events")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-telephone"></i>
                            <span>{t("contact_us")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>{t("balance_history")}</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">{t("home")}</Link>
                                </li>
                                <li className="breadcrumb-item">{t("dashboard")}</li>
                                <li className="breadcrumb-item active">{t("balance_history")}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="body genealogy-body genealogy-scroll">
                        <div
                            className="tab-pane fade active show"
                            id="all-booking"
                            role="tabpanel"
                        >
                            {isLoading ? (
                                <div className="w-100 py-5 d-flex justify-content-center">
                                    <BallTriangle
                                        height={100}
                                        width={100}
                                        radius={5}
                                        color="#4fa94d"
                                        ariaLabel="ball-triangle-loading"
                                        visible={true}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{t("amount")}</th>
                                                            <th>{t("status")}</th>
                                                            <th>{t("created_at")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paginatedData.map((transaction, index) => (
                                                            <tr key={transaction._id}>
                                                                <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                                                <td>{transaction.value}</td>
                                                                <td>{getStatusText(transaction.status)}</td>
                                                                <td>{transaction.createdAt.split("T")[0]}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className="d-flex justify-content-center align-items-center mt-4">
                                                    {/* Previous Button */}
                                                    <button
                                                        className="btn btn-primary me-2"
                                                        onClick={handlePreviousPage}
                                                        disabled={currentPage === 1}
                                                    >
                                                        {t("previous")}
                                                    </button>

                                                    {/* Page Numbers */}
                                                    {[...Array(totalPages)].map((_, page) => {
                                                        const pageNumber = page + 1;

                                                        if (
                                                            pageNumber === 1 ||
                                                            pageNumber === totalPages ||
                                                            (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                                                        ) {
                                                            return (
                                                                <button
                                                                    key={pageNumber}
                                                                    onClick={() => handlePageChange(pageNumber)}
                                                                    className={`btn mx-1 ${currentPage === pageNumber ? "btn-success" : "btn-outline-primary"
                                                                        }`}
                                                                >
                                                                    {pageNumber}
                                                                </button>
                                                            );
                                                        } else if (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) {
                                                            return (
                                                                <span key={`ellipsis-${pageNumber}`} className="mx-1">
                                                                    ...
                                                                </span>
                                                            );
                                                        }
                                                        return null;
                                                    })}

                                                    {/* Next Button */}
                                                    <button
                                                        className="btn btn-primary ms-2"
                                                        onClick={handleNextPage}
                                                        disabled={currentPage === totalPages}
                                                    >
                                                        {t("next")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>

        </>

    );
}

export default BalanceHistoryAgent;
