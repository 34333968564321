import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext"
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { IoIosSettings } from "react-icons/io";
import { BallTriangle } from 'react-loader-spinner'
import { useTranslation } from "react-i18next";
import { CgProfile } from "react-icons/cg";


function MyWallet() {
    const { logOut } = useContext(AgentContext);
    const [enteredPassword, setEnteredPassword] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState("01");
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    // Fetch agent details including walletPassword
    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true,
        });
    }

    const { data, isLoading } = useQuery("getCurrentAgent", getCurrentAgent);
    function newBina() {
        return axios.get(`https://together-v.com/api/v1/agent/agentBinaryHistory`, {
            withCredentials: true,
        });
    }

    const { data: newB } = useQuery("newBina", newBina);
    // Fetch balance history

    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]); const handlePasswordSubmit = () => {
        if (enteredPassword === data?.data?.data?.walletPassword) {
            setIsAuthenticated(true);
            setErrorMessage("");
        } else {
            setErrorMessage("Wrong password. Please try again.");
            setIsAuthenticated(false);
        }
    };

    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true
        });
    }

    let { data: prof } = useQuery("getCurrentAgent", getCurrentAgent);
    const renderContent = () => {
        switch (selectedOption) {
            case "01":
                return (
                    <>
                        <div className="col-md-4">
                            <div className="card text-center">
                                <div className="card-body color-green">
                                    <h3>{data?.data.data.balance}</h3>
                                    <h5 className="card-title">{t("balance")}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card text-center">
                                <div className="card-body color-blue">
                                    <h3>{data?.data.data.pv}</h3>
                                    <h5 className="card-title">{t("pv")}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card text-center">
                                <div className="card-body color-red">
                                    <h3>{data?.data.data.commission}</h3>
                                    <h5 className="card-title">{t("commission")}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="body genealogy-body genealogy-scroll">
                            <div
                                className="tab-pane fade active show"
                                id="all-booking"
                                role="tabpanel"
                            >
                                {isLoading ? (
                                    <div className="'w-100 py-5 d-flex justify-content-center">
                                        <BallTriangle
                                            height={100}
                                            width={100}
                                            radius={5}
                                            color="#4fa94d"
                                            ariaLabel="ball-triangle-loading"
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h2>{t("binary_report")}</h2>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>{t("#")}</th>
                                                                <th>{t("name")}</th>
                                                                <th>{t("left_before")}</th>
                                                                <th>{t("right_before")}</th>
                                                                <th>{t("left_after")}</th>
                                                                <th>{t("right_after")}</th>
                                                                <th>{t("no_pair")}</th>
                                                                <th>{t("bonus")}</th>
                                                                <th>{t("created_at")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {newB?.data.data.map((agent, index) => (
                                                                <tr key={agent._id}>
                                                                    <td>{agent.id}</td>
                                                                    <td>{agent.firstName} {agent.lastName}</td>
                                                                    <td>{agent.leftPv}</td>
                                                                    <td>{agent.rightPv}</td>
                                                                    <td>{agent.carryLeft}</td>
                                                                    <td>{agent.carryRight}</td>
                                                                    <td>{agent.currentIndirectProfitLevel}</td>
                                                                    <td>
                                                                        {/* Logic for returning values based on currentIndirectProfitLevel */}
                                                                        {(() => {
                                                                            switch (agent.currentIndirectProfitLevel) {
                                                                                case 'zero':
                                                                                    return 0;
                                                                                case 'one':
                                                                                    return 1500;
                                                                                case 'two':
                                                                                    return 3000;
                                                                                case 'three':
                                                                                    return 4500;
                                                                                case 'four':
                                                                                    return 6000;
                                                                                case 'five':
                                                                                    return 7500;
                                                                                case 'six':
                                                                                    return 9000;
                                                                                case 'seven':
                                                                                    return 10000;
                                                                                default:
                                                                                    return "Unknown Level";
                                                                            }
                                                                        })()}
                                                                    </td>
                                                                    <td>{agent.createdAt.split("T")[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {/* Pagination Controls */}

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                );
            case "02":
                return (
                    <>
                        <div className="col-md-4">
                            <div className="card text-center">
                                <div className="card-body color-green">
                                    <h3>{data?.data.data.balance}</h3>
                                    <h5 className="card-title">{t("balance")}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card text-center">
                                <div className="card-body color-blue">
                                    <h3>{data?.data.data.pv >= 2000 ? 1000 : 0}</h3>
                                    <h5 className="card-title">{t("pv")}</h5>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card text-center">
                                <div className="card-body color-red">
                                    <h3>{data?.data.data.commission2}</h3>
                                    <h5 className="card-title">{t("commission")}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="body genealogy-body genealogy-scroll">
                            <div
                                className="tab-pane fade active show"
                                id="all-booking"
                                role="tabpanel"
                            >
                                {isLoading ? (
                                    <div className="'w-100 py-5 d-flex justify-content-center">
                                        <BallTriangle
                                            height={100}
                                            width={100}
                                            radius={5}
                                            color="#4fa94d"
                                            ariaLabel="ball-triangle-loading"
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h2>{t("binary_report")}</h2>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>{t("#")}</th>
                                                                <th>{t("name")}</th>
                                                                <th>{t("left_before")}</th>
                                                                <th>{t("right_before")}</th>
                                                                <th>{t("left_after")}</th>
                                                                <th>{t("right_after")}</th>
                                                                <th>{t("no_pair")}</th>
                                                                <th>{t("bonus")}</th>
                                                                <th>{t("created_at")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {newB?.data.data.map((agent, index) => (
                                                                <tr key={agent._id}>
                                                                    <td>{agent.id}</td>
                                                                    <td>{agent.firstName} {agent.lastName}</td>
                                                                    <td>{agent.leftPv2}</td>
                                                                    <td>{agent.rightPv2}</td>
                                                                    <td>{agent.carryLeft2}</td>
                                                                    <td>{agent.carryRight2}</td>
                                                                    <td>{agent.currentIndirectProfitLevel2}</td>
                                                                    <td>
                                                                        {/* Logic for returning values based on currentIndirectProfitLevel */}
                                                                        {(() => {
                                                                            switch (agent.currentIndirectProfitLevel2) {
                                                                                case 'zero':
                                                                                    return 0;
                                                                                case 'one':
                                                                                    return 1500;
                                                                                case 'two':
                                                                                    return 3000;
                                                                                case 'three':
                                                                                    return 4500;
                                                                                case 'four':
                                                                                    return 6000;
                                                                                case 'five':
                                                                                    return 7500;
                                                                                case 'six':
                                                                                    return 9000;
                                                                                case 'seven':
                                                                                    return 10000;
                                                                                default:
                                                                                    return "Unknown Level";
                                                                            }
                                                                        })()}
                                                                    </td>
                                                                    <td>{agent.createdAt.split("T")[0]}</td>
                                                                </tr>
                                                            ))}


                                                        </tbody>
                                                    </table>
                                                    {/* Pagination Controls */}

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                );
            case "03":
                return (
                    <>
                        <div className="col-md-4">
                            <div className="card text-center">
                                <div className="card-body color-green">
                                    <h3>{data?.data.data.balance}</h3>
                                    <h5 className="card-title">{t("balance")}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card text-center">
                                <div className="card-body color-blue">
                                    <h3>{data?.data.data.pv >= 3000 ? 1000 : 0}</h3>
                                    <h5 className="card-title">{t("pv")}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card text-center">
                                <div className="card-body color-red">
                                    <h3>{data?.data.data.commission3}</h3>
                                    <h5 className="card-title">{t("commission")}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="body genealogy-body genealogy-scroll">
                            <div
                                className="tab-pane fade active show"
                                id="all-booking"
                                role="tabpanel"
                            >
                                {isLoading ? (
                                    <div className="'w-100 py-5 d-flex justify-content-center">
                                        <BallTriangle
                                            height={100}
                                            width={100}
                                            radius={5}
                                            color="#4fa94d"
                                            ariaLabel="ball-triangle-loading"
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h2>{t("binary_report")}</h2>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>{t("#")}</th>
                                                                <th>{t("name")}</th>
                                                                <th>{t("left_before")}</th>
                                                                <th>{t("right_before")}</th>
                                                                <th>{t("left_after")}</th>
                                                                <th>{t("right_after")}</th>
                                                                <th>{t("no_pair")}</th>
                                                                <th>{t("bonus")}</th>
                                                                <th>{t("created_at")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {newB?.data.data.map((agent, index) => (
                                                                <tr key={agent._id}>
                                                                    <td>{agent.id}</td>
                                                                    <td>{agent.firstName} {agent.lastName}</td>
                                                                    <td>{agent.leftPv3}</td>
                                                                    <td>{agent.rightPv3}</td>
                                                                    <td>{agent.carryLeft3}</td>
                                                                    <td>{agent.carryRight3}</td>
                                                                    <td>{agent.currentIndirectProfitLevel3}</td>
                                                                    <td>
                                                                        {/* Logic for returning values based on currentIndirectProfitLevel */}
                                                                        {(() => {
                                                                            switch (agent.currentIndirectProfitLevel3) {
                                                                                case 'zero':
                                                                                    return 0;
                                                                                case 'one':
                                                                                    return 1500;
                                                                                case 'two':
                                                                                    return 3000;
                                                                                case 'three':
                                                                                    return 4500;
                                                                                case 'four':
                                                                                    return 6000;
                                                                                case 'five':
                                                                                    return 7500;
                                                                                case 'six':
                                                                                    return 9000;
                                                                                case 'seven':
                                                                                    return 10000;
                                                                                default:
                                                                                    return "Unknown Level";
                                                                            }
                                                                        })()}
                                                                    </td>
                                                                    <td>{agent.createdAt.split("T")[0]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {/* Pagination Controls */}

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };
    const renderTable = () => {
        let filteredData = [];
        switch (selectedOption) {
            case "01":
                filteredData = data?.data?.data; // Use all data for option "01"
                break;
            case "02":
                filteredData = data?.data?.data;
                break;
            case "03":
                filteredData = data?.data?.data;
                break;
            default:
                break;
        }
        const renderContent = () => {
            return (
                <>
                    <div className="col-md-4">
                        <div className="card text-center">
                            <div className="card-body color-green">
                                <h3>{prof?.data.data.balance}</h3>
                                <h5 className="card-title">{t("balance")}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-center">
                            <div className="card-body color-blue">
                                <h3>
                                    {selectedOption === "01"
                                        ? prof?.data.data.leftPv
                                        : selectedOption === "02"
                                            ? prof?.data.data.rightPv
                                            : prof?.data.data.commission}
                                </h3>
                                <h5 className="card-title">{t("pv")}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-center">
                            <div className="card-body color-red">
                                <h3>{prof?.data.data.commission}</h3>
                                <h5 className="card-title">{t("commission")}</h5>
                            </div>
                        </div>
                    </div>
                    {renderTable()}
                </>
            );
        };

        return (
            <>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{t("#")}</th>
                            <th>{t("from")}</th>
                            <th>{t("status")}</th>
                            <th>{t("to")}</th>
                            <th>{t("created_at")}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {filteredData.map((transaction, index) => (
                            <tr key={transaction._id}>
                                <td>{index + 1}</td>
                                <td>{transaction.from}</td>
                                <td>{transaction.request}</td>
                                <td>{transaction.to}</td>
                                <td>{transaction.createdAt.split("T")[0]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </>
        );
    };
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>My Wallet</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>

                </div>
                <div className="d-flex mx-auto">
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link "
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span>{t("language")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("en")}
                                >
                                    English
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("ar")}
                                >
                                    العربية
                                </button>
                            </li>
                        </ul>
                    </li>
                </div>


                <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">
                        <li class="nav-item dropdown ">
                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                {data?.data.data.profileImage ? (
                                    <img
                                        src={data?.data.data.profileImage}
                                        alt="Profile"
                                        className="rounded-circle"
                                    />
                                ) : (
                                    <CgProfile size={30} className="rounded-circle" />
                                )}                                <span class="d-none d-md-block dropdown-toggle ps-2">{data?.data.data.firstName} {data?.data.data.secondName}</span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>{t("my_profile")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>{t("sign_out")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <h2 className="menu-icon" onClick={toggleSidebar}>
                    <FiAlignJustify />
                </h2>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-tree"></i>
                            <span>{t("my_tree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-person-plus"></i>
                            <span>{t("add_agent")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box"></i>
                            <span>{t("all_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box"></i>
                            <span>{t("my_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-wallet2"></i>
                            <span>{t("my_wallet")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-arrow-left-right"></i>
                            <span>{t("transfers")}</span>
                        </Link>
                    </li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-file-earmark-text"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    {t("balance_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    {t("transfer_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    {t("withdraw_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/BinaryDetailsAgent">
                                    {t("binary_details")}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-cash"></i>
                            <span>{t("withdraw")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/profile">
                            <i className="bi bi-person"></i>
                            <span>{t("my_profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-calendar"></i>
                            <span>{t("all_events")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-telephone"></i>
                            <span>{t("contact_us")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <div id="main" className="bg-light-2">
                <div className="pagetitle">
                    <h1>{t("my_wallet")}</h1>
                </div>
                {!isAuthenticated ? (
                    <div className="container mt-4 text-center">
                        <h3>{t("enter_password_prompt")}</h3>
                        <input
                            type="password"
                            className="form-control w-50 mx-auto"
                            value={enteredPassword}
                            onChange={(e) => setEnteredPassword(e.target.value)}
                            placeholder={t("wallet_password_placeholder")}
                            style={{
                                textAlign: i18n.language === "ar" ? "right" : "left",
                            }}
                        />
                        <button
                            className="btn btn-primary mt-3"
                            onClick={handlePasswordSubmit}
                        >
                            {t("submit")}
                        </button>
                        {errorMessage && (
                            <p className="text-danger mt-2" style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}>
                                {errorMessage}
                            </p>
                        )}                    </div>
                ) : (
                    <div className="container mt-4">
                        <div className="form-group mb-3 d-flex align-items-center">
                            <label className="me-3 fw-bolder">{t("select_account")}:</label>
                            <select
                                className="form-control w-25"
                                value={selectedOption}
                                onChange={(e) => setSelectedOption(e.target.value)}
                                style={{
                                    textAlign: i18n.language === "ar" ? "right" : "left",
                                }}
                            >
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                            </select>
                        </div>
                        <div className="row mt-4">{renderContent()}</div>
                    </div>
                )}
            </div>


        </>
    );
}

export default MyWallet;
