import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext"
import logoo from "../../imges/logo.png"
import { IoIosSettings } from "react-icons/io";

import { FiAlignJustify } from "react-icons/fi";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { CgProfile } from "react-icons/cg";

function AllProductsAgent() {
    const { logOut } = useContext(AgentContext);
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);
    const [loading, setLoading] = useState(false); // Add loading state
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get("https://restcountries.com/v3.1/all");
                setCountries(response.data);
            } catch (error) {
                toast.error("Failed to load countries data.");
            }
        };
        fetchCountries();
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const { data: products } = useQuery("getAllProducts", async () => {
        const response = await axios.get("https://together-v.com/api/v1/product/allProducts", {
            params: { limit: 1000000, page: 1 },
            withCredentials: true,
        });
        return response.data;
    });

    const formik = useFormik({
        initialValues: {
            governorate: "",
            city: "",
            street: "",
            phone: "",
        },
        onSubmit: async (values) => {
            setShowConfirmation(true);
        },
    });

    const handleConfirmPurchase = async () => {
        setLoading(true); // Set loading state to true
        try {
            const token = localStorage.getItem("AgentToken");

            const payload = {
                ...formik.values,
                city: formik.values.city || "", // Include city as an empty string if not provided
            };

            const response = await axios.post(
                `https://together-v.com/api/v1/enrollment/${selectedProductId}`,
                payload,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    withCredentials: true,
                }
            );

            if (response.data.message === "product bought successfully") {
                toast.success("Product bought successfully.");
                setShowConfirmation(false);
                formik.resetForm();
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to purchase product.");
            console.log(error)
        } finally {
            setLoading(false); // Set loading state to false after completion
        }
    };

    const handleCountryChange = (e) => {
        const country = e.target.value;
        formik.setFieldValue("governorate", country);
        formik.setFieldValue("city", ""); // Reset city if country changes
    };
    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true
        });
    }

    let { data: prof } = useQuery("getCurrentAgent", getCurrentAgent);
    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                    <title>All Products</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>

                </div>
                <div className="d-flex mx-auto">
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link "
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span>{t("language")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("en")}
                                >
                                    English
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("ar")}
                                >
                                    العربية
                                </button>
                            </li>
                        </ul>
                    </li>
                </div>


                <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">
                        <li class="nav-item dropdown ">
                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                {prof?.data.data.profileImage ? (
                                    <img
                                        src={prof?.data.data.profileImage}
                                        alt="Profile"
                                        className="rounded-circle"
                                    />
                                ) : (
                                    <CgProfile size={30} className="rounded-circle" />
                                )}                                <span class="d-none d-md-block dropdown-toggle ps-2">{prof?.data.data.firstName} {prof?.data.data.secondName}</span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>{t("my_profile")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>{t("sign_out")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <h2 className="menu-icon" onClick={toggleSidebar}>
                    <FiAlignJustify />
                </h2>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-tree"></i>
                            <span>{t("my_tree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-person-plus"></i>
                            <span>{t("add_agent")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box"></i>
                            <span>{t("all_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box"></i>
                            <span>{t("my_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-wallet2"></i>
                            <span>{t("my_wallet")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-arrow-left-right"></i>
                            <span>{t("transfers")}</span>
                        </Link>
                    </li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-file-earmark-text"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    {t("balance_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    {t("transfer_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    {t("withdraw_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/BinaryDetailsAgent">
                                    {t("binary_details")}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-cash"></i>
                            <span>{t("withdraw")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/profile">
                            <i className="bi bi-person"></i>
                            <span>{t("my_profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-calendar"></i>
                            <span>{t("all_events")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-telephone"></i>
                            <span>{t("contact_us")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>{t("all_products")}</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/dashboard">{t("home")}</a></li>
                                <li className="breadcrumb-item active">{t("all_products")}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="body genealogy-body genealogy-scroll">
                        <div className="container">
                            <div className="row">
                                {Array.isArray(products?.data) && products?.data.length > 0 ? (
                                    products.data.map((product) => (
                                        <div key={product._id} className="col-lg-4 mb-4">
                                            <Link to={`/ProductDetailsAgent/${product._id}`}>
                                                <img
                                                    src={product.imgs?.[0] || "placeholder.jpg"}
                                                    alt={product.name}
                                                    className="h-200px w-100"
                                                />
                                                <div className="card-body" align={i18n.language === "ar" ? "right" : "left"}>
                                                    <p>{product.category}</p>
                                                    <h3>{product.name}</h3>
                                                    <div className="row">
                                                        <div className="col-lg-6">{t("price")}: {product.price} {t("currency")}</div>
                                                        <div className="col-lg-6">{t("you_will_get")} {product.pv} {t("pv")}</div>
                                                    </div>
                                                    <p>{t("quantity")}: {product.quantity}</p>
                                                </div>
                                            </Link>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    setSelectedProductId(product._id);
                                                    setShowForm(true);
                                                }}
                                            >
                                                {t("buy_now")}
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <p>{t("no_products_available")}</p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Form Modal */}
                    {showForm && (
                        <div className="modal show d-block">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{t("fill_your_details")}</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => setShowForm(false)}
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={formik.handleSubmit}>
                                            {/* Country Selection */}
                                            <div className="mb-3">
                                                <label className="form-label">{t("country")}</label>
                                                <select
                                                    name="country"
                                                    className="form-control"
                                                    value={formik.values.governorate}
                                                    onChange={handleCountryChange}
                                                >
                                                    <option value="">{t("select_country")}</option>
                                                    {countries.map((country) => (
                                                        <option key={country.cca3} value={country.name.common}>
                                                            {country.name.common}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/* City Selection */}
                                            {formik.values.governorate === "Egypt" && (
                                                <div className="mb-3">
                                                    <label className="form-label">{t("city")}</label>
                                                    <select
                                                        name="city"
                                                        className="form-control"
                                                        value={formik.values.city}
                                                        onChange={formik.handleChange}
                                                    >
                                                        <option value="">{t("select_city")}</option>
                                                        <option value="Cairo">{t("cairo")}</option>
                                                        <option value="Alexandria">{t("alexandria")}</option>
                                                        <option value="Giza">{t("giza")}</option>
                                                        <option value="Port Said">{t("port_said")}</option>
                                                        <option value="Suez">{t("suez")}</option>
                                                        <option value="Mansoura">{t("mansoura")}</option>
                                                        <option value="Tanta">{t("tanta")}</option>
                                                        <option value="Ismailia">{t("ismailia")}</option>
                                                        <option value="Faiyum">{t("faiyum")}</option>
                                                        <option value="Damietta">{t("damietta")}</option>
                                                        <option value="Asyut">{t("asyut")}</option>
                                                        <option value="Aswan">{t("aswan")}</option>
                                                        <option value="Minya">{t("minya")}</option>
                                                        <option value="Beni Suef">{t("beni_suef")}</option>
                                                        <option value="Qena">{t("qena")}</option>
                                                        <option value="Sohag">{t("sohag")}</option>
                                                        <option value="Hurghada">{t("hurghada")}</option>
                                                        <option value="Luxor">{t("luxor")}</option>
                                                        <option value="Qalyubia">{t("qalyubia")}</option>
                                                        <option value="Sharqia">{t("sharqia")}</option>
                                                        <option value="Beheira">{t("beheira")}</option>
                                                        <option value="Kafr El Sheikh">{t("kafr_el_sheikh")}</option>
                                                        <option value="Monufia">{t("monufia")}</option>
                                                        <option value="Red Sea">{t("red_sea")}</option>
                                                        <option value="North Sinai">{t("north_sinai")}</option>
                                                        <option value="South Sinai">{t("south_sinai")}</option>
                                                        <option value="Matruh">{t("matruh")}</option>
                                                        <option value="New Valley">{t("new_valley")}</option>
                                                    </select>
                                                </div>
                                            )}

                                            {/* Other Address Fields */}
                                            {["street", "phone"].map(
                                                (field) => (
                                                    <div key={field} className="mb-3">
                                                        <label className="form-label">
                                                            {t(field)}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name={field}
                                                            className="form-control"
                                                            value={formik.values[field]}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </div>
                                                )
                                            )}
                                            <button className="btn btn-primary" type="submit">
                                                {t("submit")}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Confirmation Modal */}
                    {showConfirmation && (
                        <div className="modal show d-block">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{t("confirmation")}</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => setShowConfirmation(false)}
                                            disabled={loading} // Disable close button while loading
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {t("purchase_notice")}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            className="btn btn-secondary"
                                            onClick={() => setShowConfirmation(false)}
                                            disabled={loading} // Disable cancel button while loading
                                        >
                                            {t("cancel")}
                                        </button>
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleConfirmPurchase}
                                            disabled={loading} // Disable confirm button while loading
                                        >
                                            {loading ? t("processing") : t("confirm")} {/* Show loading text */}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </section>



        </>

    );
}

export default AllProductsAgent;
