import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { AdminContext } from "../../Context/AdminContext"
import { jsPDF } from "jspdf";
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { BallTriangle } from 'react-loader-spinner'
import { useTranslation } from "react-i18next";

function AllRequestsProducts() {
    let { logOut } = useContext(AdminContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();
    const queryClient = useQueryClient();
    const recordsPerPage = 10; // Records per page
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [searchId, setSearchId] = useState("");
    const [filteredAgent, setFilteredAgent] = useState(null);
    const { t, i18n } = useTranslation(); // Translation hook
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    // Fetch data for completed enrollments
    function getAllEnrollments() {
        return axios.get(`https://together-v.com/api/v1/enrollment/carts`, {
            withCredentials: true,
        });
    }

    let { data, isLoading } = useQuery("getAllEnrollments", getAllEnrollments);
    console.log(data?.data.data)
    // Download PDF function
    const downloadPDF = () => {
        const doc = new jsPDF();
        let y = 10;

        // Title
        doc.setFontSize(16);
        doc.text("All Product Requests", 10, y);
        y += 10;

        // Iterate through each request and create a vertical structure
        data?.data.data.forEach((agent, index) => {
            const agentName = agent.agentId
                ? `${agent.agentId.firstName} ${agent.agentId.lastName}`
                : "N/A";
            const phone = agent.agentId?.phone || agent.phone || "N/A";

            // Add headers and data as rows
            doc.setFontSize(12);
            doc.text(`Request No. ${index + 1}`, 10, y);
            y += 10;
            doc.text("Name:", 10, y);
            doc.text(agentName, 50, y);
            y += 10;
            doc.text("Date:", 10, y);
            doc.text(agent.createdAt?.split("T")[0] || "N/A", 50, y);
            y += 10;
            doc.text("Product Name:", 10, y);
            doc.text(agent.productId.name || "N/A", 50, y);
            y += 10;
            doc.text("Phone:", 10, y);
            doc.text(phone, 50, y);
            y += 10;
            doc.text("Price:", 10, y);
            doc.text(String(agent.productId.price || "N/A"), 50, y);
            y += 10;
            doc.text("Apartment Number:", 10, y);
            doc.text(String(agent.apartmentNumber || "N/A"), 50, y);
            y += 10;
            doc.text("Building Number:", 10, y);
            doc.text(String(agent.buildingNumber || "N/A"), 50, y);
            y += 10;
            doc.text("City:", 10, y);
            doc.text(agent.city || "N/A", 50, y);
            y += 10;
            doc.text("Floor Number:", 10, y);
            doc.text(String(agent.floorNumber || "N/A"), 50, y);
            y += 10;
            doc.text("Governorate:", 10, y);
            doc.text(agent.governorate || "N/A", 50, y);
            y += 10;
            doc.text("Street:", 10, y);
            doc.text(agent.street || "N/A", 50, y);
            y += 20; // Add extra space before the next request

            // Check for page overflow
            if (y > 270) {
                doc.addPage();
                y = 10;
            }
        });

        // Save the PDF
        doc.save("ProductRequests.pdf");



    };

    const handleStatusChange = async (agentId, newStatus) => {
        if (!agentId) {
            toast.error("Invalid agent ID. Cannot update status.");
            return;
        }

        const token = localStorage.getItem("AdminToken");
        if (!token) {
            toast.error("No AdminToken found. Please log in.");
            navigate("/login");
            return;
        }

        try {
            const encodedStatus = encodeURIComponent(newStatus);
            const url = `https://together-v.com/api/v1/enrollment/shippingStatus/${agentId}?status=${encodedStatus}`;

            await axios.post(url, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
            });

            toast.success(`Status successfully updated to "${newStatus}".`);
            queryClient.invalidateQueries(getAllEnrollments);

        } catch (error) {
            const errorMessage = error.response?.data?.message || "Failed to update status.";
            toast.error(errorMessage);
        }
    };
    const records = data?.data?.data || [];
    const totalPages = Math.ceil(records.length / recordsPerPage);

    const paginatedData = records.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
    );

    const handlePageChange = (page) => setCurrentPage(page);
    const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    const handlePreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    const handleSearch = async () => {
        if (searchId.trim() === "") {
            setFilteredAgent(null); // Reset filteredAgent if search field is empty
            return;
        }

        try {
            // Determine if searchId is an agentId (assume it's alphanumeric) or shippingStatus (assume it's text)
            const isAgentId = /^[a-zA-Z0-9]+$/.test(searchId); // Example logic to differentiate

            // Build the appropriate query parameter
            const queryParam = isAgentId
                ? `agentId=${searchId}` // Search by agentId
                : `shippingStatus=${encodeURIComponent(searchId)}`; // Search by shippingStatus

            const response = await axios.get(
                `https://together-v.com/api/v1/enrollment/carts?${queryParam}`, // Use the dynamically constructed query parameter
                {
                    withCredentials: true,
                }
            );

            if (response.data?.data && response.data.data.length > 0) {
                setFilteredAgent(response.data.data); // Set filteredAgent with the API response
                queryClient.invalidateQueries(getAllEnrollments);

            } else {
                setFilteredAgent([]); // Set to empty array if no results are found
                toast.error("No matching data found!");
            }

            console.log("Search Results:", response.data.data);
        } catch (error) {
            console.error("Error fetching agent data:", error);
            setFilteredAgent([]); // Reset filteredAgent on error
            toast.error("Failed to fetch data. Please try again.");
        }
    };

    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);



    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>All Purchases</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center justify-content-between w-100">
                    {/* Logo */}
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/DashboardAdmin")}
                        />
                    </div>

                    {/* Language Toggle Buttons */}
                    <div className="d-flex">
                        <li className="nav-item dropdown bg-body">
                            <Link
                                className="nav-link "
                                to="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <span>{t("language")}</span>
                            </Link>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <button
                                        className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                        onClick={() => changeLanguage("en")}
                                    >
                                        English
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                        onClick={() => changeLanguage("ar")}
                                    >
                                        العربية
                                    </button>
                                </li>

                            </ul>
                        </li>

                    </div>

                    {/* Sidebar Toggle */}
                    <h2 className="menu-icon" onClick={toggleSidebar}>
                        <FiAlignJustify />
                    </h2>
                </div>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>{t("allAgents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/NewId">
                            <i className="bi bi-grid"></i>
                            <span>{t("changeID")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addEpin")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>{t("ePinsHistory")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addProduct")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("allProducts")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("myTree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addEvents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ViewAllEventsAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("viewEvents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("withdrawRequests")}</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("myTransfers")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allPurchase")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allTransfers")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allWithdraws")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/BinaryReports">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("binaryDetails")}</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("filtration")}</span>
                        </Link>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>{t("allContact")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>{t("profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>{t("logout")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>



            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>{t("all_purchases")}</h1>
                        <button className="btn btn-primary my-3" onClick={downloadPDF}>
                            {t("download_as_pdf")}
                        </button>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="index.html">{t("home")}</a>
                                </li>
                                <li className="breadcrumb-item">{t("dashboard")}</li>
                                <li className="breadcrumb-item active">{t("all_purchases")}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="body genealogy-body genealogy-scroll">
                        <div className="text-center mb-2">
                            <input
                                type="text"
                                placeholder={t("enter_agent_id")}
                                value={searchId}
                                onChange={(e) => setSearchId(e.target.value)}
                                className="form-control w-100"
                            />
                            <button
                                className="btn btn-primary w-25 mt-3"
                                onClick={handleSearch}
                            >
                                {t("search")}
                            </button>
                        </div>
                        <div
                            className="tab-pane fade active show"
                            id="all-booking"
                            role="tabpanel"
                        >
                            {isLoading ? <div className="'w-100 py-5 d-flex justify-content-center">
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#4fa94d"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div> : <>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">{t("name")}</th>
                                                        <th scope="col">{t("id")}</th>
                                                        <th scope="col">{t("product_name")}</th>
                                                        <th scope="col">{t("current_status")}</th>
                                                        <th scope="col">{t("update_status")}</th>
                                                        <th scope="col">{t("price")}</th>
                                                        <th scope="col">{t("date")}</th>
                                                        <th scope="col">{t("view")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredAgent && filteredAgent.length > 0
                                                        ? filteredAgent.map((agent, index) => (
                                                            <tr key={agent._id || index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    {agent.agentId
                                                                        ? `${agent.agentId.firstName || t("n_a")} ${agent.agentId.lastName || t("n_a")}`
                                                                        : t("n_a")}
                                                                </td>
                                                                <td>{agent.agentId?.id || t("n_a")}</td>
                                                                <td>{agent.productId?.name || t("n_a")}</td>
                                                                <td>{agent.shippingStatus}</td>
                                                                <td>
                                                                    <select
                                                                        onChange={(e) => handleStatusChange(agent._id, e.target.value)}
                                                                        className="form-control w-100"
                                                                    >
                                                                        <option value="">{t("select_status")}</option>
                                                                        <option value="تم استلام الطلب">{t("order_received")}</option>
                                                                        <option value="تم تأكيد الطلب">{t("order_confirmed")}</option>
                                                                        <option value="في انتظار شركة الشحن">{t("awaiting_shipping")}</option>
                                                                        <option value="التوصيل قيد التقدم">{t("delivery_in_progress")}</option>
                                                                        <option value="تم التوصيل">{t("delivered")}</option>
                                                                        <option value="طلبات معلقة">{t("pending_orders")}</option>
                                                                        <option value="تم الغاء الطلب">{t("order_cancelled")}</option>
                                                                    </select>
                                                                </td>
                                                                <td>{agent.productId?.price || t("n_a")}</td>
                                                                <td>{agent.createdAt?.split("T")[0] || t("n_a")}</td>
                                                                <td>
                                                                    <Link
                                                                        to={`/ViewProductBoughtDetails/${agent._id}`}
                                                                        className="btn btn-warning"
                                                                    >
                                                                        {t("view")}
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                        ) : filteredAgent && filteredAgent.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="9" className="text-center">
                                                                    {t("no_matching_data")}
                                                                </td>
                                                            </tr>
                                                        ) :
                                                            paginatedData?.map((agent, index) => (
                                                                <tr key={agent._id || index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        {agent.agentId
                                                                            ? `${agent.agentId.firstName || t("n_a")} ${agent.agentId.lastName || t("n_a")}`
                                                                            : t("n_a")}
                                                                    </td>
                                                                    <td>{agent.agentId?.id || t("n_a")}</td>
                                                                    <td>{agent.productId?.name || t("n_a")}</td>
                                                                    <td>{agent.shippingStatus}</td>
                                                                    <td>
                                                                        <select
                                                                            onChange={(e) => handleStatusChange(agent._id, e.target.value)}
                                                                            className="form-control w-100"
                                                                        >
                                                                            <option value="">{t("select_status")}</option>
                                                                            <option value="تم استلام الطلب">{t("order_received")}</option>
                                                                            <option value="تم تأكيد الطلب">{t("order_confirmed")}</option>
                                                                            <option value="في انتظار شركة الشحن">{t("awaiting_shipping")}</option>
                                                                            <option value="التوصيل قيد التقدم">{t("delivery_in_progress")}</option>
                                                                            <option value="تم التوصيل">{t("delivered")}</option>
                                                                            <option value="طلبات معلقة">{t("pending_orders")}</option>
                                                                            <option value="تم الغاء الطلب">{t("order_cancelled")}</option>
                                                                        </select>
                                                                    </td>
                                                                    <td>{agent.productId?.price || t("n_a")}</td>
                                                                    <td>{agent.createdAt?.split("T")[0] || t("n_a")}</td>
                                                                    <td>
                                                                        <Link
                                                                            to={`/ViewProductBoughtDetails/${agent._id}`}
                                                                            className="btn btn-warning"
                                                                        >
                                                                            {t("view")}
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                </tbody>
                                                <tbody>

                                                </tbody>


                                            </table>
                                            <div className="d-flex justify-content-center align-items-center mt-4">
                                                <button
                                                    className="btn btn-primary me-2"
                                                    onClick={handlePreviousPage}
                                                    disabled={currentPage === 1}
                                                >
                                                    {t("previous")}
                                                </button>
                                                {[...Array(totalPages)].map((_, page) => {
                                                    const pageNumber = page + 1;
                                                    if (
                                                        pageNumber === 1 ||
                                                        pageNumber === totalPages ||
                                                        (pageNumber >= currentPage - 1 &&
                                                            pageNumber <= currentPage + 1)
                                                    ) {
                                                        return (
                                                            <button
                                                                key={pageNumber}
                                                                onClick={() => handlePageChange(pageNumber)}
                                                                className={`btn mx-1 ${currentPage === pageNumber
                                                                    ? "btn-success"
                                                                    : "btn-outline-primary"
                                                                    }`}
                                                            >
                                                                {pageNumber}
                                                            </button>
                                                        );
                                                    } else if (
                                                        pageNumber === currentPage - 2 ||
                                                        pageNumber === currentPage + 2
                                                    ) {
                                                        return (
                                                            <span
                                                                key={`ellipsis-${pageNumber}`}
                                                                className="mx-1"
                                                            >
                                                                ...
                                                            </span>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <button
                                                    className="btn btn-primary ms-2"
                                                    onClick={handleNextPage}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    {t("next")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}


                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default AllRequestsProducts;
