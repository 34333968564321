import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { AdminContext } from "../../Context/AdminContext"
import logoo from "../../imges/logo.png"
import toast from "react-hot-toast";
import { FiAlignJustify } from "react-icons/fi";
import { useTranslation } from "react-i18next";


function DashboardAdmin() {
    let { logOut } = useContext(AdminContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    const [commissionStatus, setCommissionStatus] = useState("");
    let navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
    const recordsPerPage = 10; // Number of records per page
    const { t, i18n } = useTranslation(); // Translation hook
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    function getAllAgents() {
        return axios.get(`https://together-v.com/api/v1/agent/allAgents`, {
            withCredentials: true
        });
    }

    let { data } = useQuery('getAllAgents', getAllAgents);


    function getAllPendings() {
        return axios.get(`https://together-v.com/api/v1/withdraw/allPendingRequests`, {
            withCredentials: true
        });
    }
    let response = useQuery('getAllPendings', getAllPendings);

    function getAllCompletes() {
        return axios.get(`https://together-v.com/api/v1/withdraw/allCompletedRequests`, {
            withCredentials: true
        });
    }
    let allCompletes = useQuery('getAllCompletes', getAllCompletes);

    function getAllProfitable() {
        return axios.get(`https://together-v.com/api/v1/agent/moreProfitable`, {
            withCredentials: true
        });
    }
    let allProfitable = useQuery('getAllProfitable', getAllProfitable);
    const totalRecords = allProfitable?.data?.data?.data?.length || 0;
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const paginatedData = allProfitable?.data?.data?.data?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
    );

    const handlePageChange = (page) => setCurrentPage(page);
    const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    const handlePreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

    function allProductss() {
        const limit = 1000000;
        const page = 1;

        return axios.get(`https://together-v.com/api/v1/product/allProducts`, {
            params: {
                limit,
                page,
            },
            withCredentials: true
        });
    }

    let AllProductss = useQuery('allProductss', allProductss);

    function getAllPurchase() {
        return axios.get(`https://together-v.com/api/v1/agent/AgentsTransferHistory`, {
            withCredentials: true
        });
    }
    let GetAllPurchase = useQuery('getAllPurchase', getAllPurchase);
    function getAllEnrollments() {
        return axios.get(`https://together-v.com/api/v1/enrollment/carts`, {
            withCredentials: true,
        });
    }

    let { data: enroll, isLoading } = useQuery("getAllEnrollments", getAllEnrollments);
    const handleTopLevelCommissionChange = async () => {
        const validValues = ["true", "false"];
        const trimmedValue = commissionStatus.trim().toLowerCase();

        if (!validValues.includes(trimmedValue)) {
            toast.error("Please enter a valid value: 'true' or 'false'.");
            return;
        }

        try {
            // Add commission as a query parameter in the URL
            const response = await axios.post(
                `https://together-v.com/api/v1/agent/changeCommissionStatus/{agentId}?commission=${trimmedValue}`,
                {}, // Empty body, since data is in query parameters
                { withCredentials: true }
            );

            if (response.data?.message === "Commission status changed successfully") {
                toast.success(`Commission status updated: ${response.data.data.status}`);
            } else {
                toast.error("Unexpected response from the server. Please try again.");
            }
        } catch (error) {
            toast.error("Failed to update commission status. Please try again.");
        }
    };

    const handleAddCommissionNow = async () => {
        try {
            // Retrieve the token from localStorage or your preferred storage
            const token = localStorage.getItem("AdminToken");

            // Call the API to add commissions
            const response = await axios.get(
                "https://together-v.com/api/v1/agent/updateHoldingCommissions",
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Pass token in Authorization header
                    },
                    withCredentials: true, // Ensure credentials are sent
                }
            );

            if (response.data?.message === "Commission status changed successfully") {
                toast.success("Commissions added successfully!");
            } else {
                toast.error("Unexpected response from the server. Please try again.");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to add commissions. Please try again.");
        }
    };

    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Dashboard</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center justify-content-between w-100">
                    {/* Logo */}
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/DashboardAdmin")}
                        />
                    </div>

                    {/* Language Toggle Buttons */}
                    <div className="d-flex">
                        <li className="nav-item dropdown bg-body">
                            <Link
                                className="nav-link "
                                to="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <span>{t("language")}</span>
                            </Link>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <button
                                        className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                        onClick={() => changeLanguage("en")}
                                    >
                                        English
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                        onClick={() => changeLanguage("ar")}
                                    >
                                        العربية
                                    </button>
                                </li>

                            </ul>
                        </li>

                    </div>

                    {/* Sidebar Toggle */}
                    <h2 className="menu-icon" onClick={toggleSidebar}>
                        <FiAlignJustify />
                    </h2>
                </div>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>{t("allAgents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/NewId">
                            <i className="bi bi-grid"></i>
                            <span>{t("changeID")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addEpin")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>{t("ePinsHistory")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addProduct")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("allProducts")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("myTree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addEvents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ViewAllEventsAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("viewEvents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("withdrawRequests")}</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("myTransfers")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allPurchase")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allTransfers")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allWithdraws")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/BinaryReports">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("binaryDetails")}</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("filtration")}</span>
                        </Link>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>{t("allContact")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>{t("profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>{t("logout")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>



            <div id="main" className="bg-light-2">
                <div className="pagetitle">
                    <h1>{t("dashboardTitle")}</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">{t("home")}</a></li>
                            <li className="breadcrumb-item active">{t("dashboardTitle")}</li>
                        </ol>
                    </nav>
                </div>
                <div>
                    <div className="container mt-4">

                        <div className="row">
                            <div className=" mb-4">
                                <div className="col-lg-6">
                                    <label htmlFor="commissionStatus">{t("commissionStatusForFriday")} </label>
                                    <select
                                        id="commissionStatus"
                                        className="form-control"
                                        value={commissionStatus}
                                        onChange={(e) => setCommissionStatus(e.target.value)}
                                    >
                                        <option value="">{t("selectStatus")}</option>
                                        <option value="true">{t("true")}</option>
                                        <option value="false">{t("false")}</option>
                                    </select>
                                </div>
                                <div className="col-lg-6  mt-3">
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleTopLevelCommissionChange}
                                    >
                                        {t("updateCommissionStatus")}
                                    </button>
                                </div>
                            </div>
                            <label htmlFor="" className="mt-3">{t("commissionStatusToAddNow")}</label>
                            <div className="col-lg-12 mt-2 mb-4">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleAddCommissionNow}
                                >
                                    {t("addCommissionNow")}
                                </button>
                            </div>






                            <div className="col-md-4 mt-5">
                                <div className="card text-center">
                                    <div className="card-body" id="agentsNum">
                                        <h3>{data?.data?.data?.length}</h3>
                                        <h5 className="card-title">{t("allAgents")}</h5>
                                        <Link to="/Agents" className="btn btn-primary">{t("viewDetails")}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-5">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h3>{AllProductss?.data?.data?.data?.length}</h3>
                                        <h5 className="card-title">{t("allProducts")}</h5>
                                        <Link to="/AllProducts" className="btn btn-primary">{t("viewDetails")}</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 mt-5">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h3>{response?.data?.data?.data?.length}</h3>
                                        <h5 className="card-title">{t("pendingWithdrawRequests")}</h5>
                                        <Link to="/WithDrawRequest" className="btn btn-primary">{t("viewDetails")}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h3>{allCompletes?.data?.data?.data?.length}</h3>
                                        <h5 className="card-title">{t("CompletedWithdrawlsRequests")}</h5>
                                        <Link to="/ViewCompletedRequests" className="btn btn-primary">{t("viewDetails")}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <h3>{enroll?.data?.data?.length}</h3>
                                        <h5 className="card-title">{t("AllPurchasesRequests")}</h5>
                                        <Link to="/AllRequestsProducts" className="btn btn-primary">{t("viewDetails")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 className="text-center mt-4">{t("moreProfitable")}</h2>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{t("name")}</th>
                                    <th scope="col">{t("balance")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(paginatedData) ? (
                                    paginatedData.map((agent, index) => (
                                        <tr key={agent._id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {agent.firstName} {agent.lastName}
                                            </td>
                                            <td>
                                                {agent.balance}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">{t("noDataAvailable")}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <button
                                className="btn btn-primary me-2"
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                            >
                                {t("previous")}
                            </button>

                            {[...Array(totalPages)].map((_, page) => {
                                const pageNumber = page + 1;
                                if (
                                    pageNumber === 1 ||
                                    pageNumber === totalPages ||
                                    (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                                ) {
                                    return (
                                        <button
                                            key={pageNumber}
                                            onClick={() => handlePageChange(pageNumber)}
                                            className={`btn mx-1 ${currentPage === pageNumber ? "btn-success" : "btn-outline-primary"}`}
                                        >
                                            {pageNumber}
                                        </button>
                                    );
                                } else if (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) {
                                    return (
                                        <span key={`ellipsis-${pageNumber}`} className="mx-1">
                                            ...
                                        </span>
                                    );
                                }
                                return null;
                            })}

                            <button
                                className="btn btn-primary ms-2"
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            >
                                {t("next")}
                            </button>
                        </div>




                    </div>

                </div>

            </div>




        </>
    );
}

export default DashboardAdmin;
