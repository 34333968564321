import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { IoIosSettings } from "react-icons/io";
import { CgProfile } from "react-icons/cg";

import { AgentContext } from "../../Context/AgentContext"
import axios from "axios";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
function Profile() {
    let { logOut } = useContext(AgentContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    let navigate = useNavigate();
    const [isWalletLoading, setIsWalletLoading] = useState(false); // Loading state for wallet password change
    const [selectedImage, setSelectedImage] = useState(null); // To hold the selected file
    const [previewImage, setPreviewImage] = useState("assets/img/profile-img.jpg"); // Default preview image
    const [isUploading, setIsUploading] = useState(false); // Uploading state
    const [selectedCoverImage, setSelectedCoverImage] = useState(null);
    const [previewCoverImage, setPreviewCoverImage] = useState("assets/img/default-cover.jpg");
    const [isCoverUploading, setIsCoverUploading] = useState(false);
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true,
        });
    }

    let { data } = useQuery("getCurrentAgent", getCurrentAgent);
    const handleProfileUpdate = async (e) => {
        e.preventDefault();

        const updatedData = {
            address: e.target.address.value,
            country: e.target.country.value,
            governorate: e.target.governorate.value,
            city: e.target.city.value,
            inheritorName: e.target.inheritorName.value,
            grandfatherInheritorName: e.target.grandfatherInheritorName.value,
            zipCode: e.target.zipCode.value,

        };

        try {
            const token = localStorage.getItem("AgentToken");

            const response = await axios.post(
                `https://together-v.com/api/v1/agent/updateAgent`,
                updatedData,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.message === "Agent updated successfully") {
                toast.success("Profile updated successfully");
            }
        } catch (err) {
            toast.error(err.response?.data?.message || "An unexpected error occurred.");
        }
    };
    const handlePasswordChange = async (values) => {
        setIsLoading(true); // Set loading to true when submitting

        try {
            const response = await axios.post(
                `https://together-v.com/api/v1/agent/updatePasswordByAgent`,
                {
                    oldPass: values.currentPassword,
                    newPass: values.newPassword,
                },
                { withCredentials: true }
            );

            if (response.data.message === "Password updated successfully") {
                toast.success("Password updated successfully!");
            } else {
                toast.error("Failed to update password");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
        finally {
            setIsLoading(false); // Set loading to false after completion
        }
    };
    const passwordFormik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            reenterPassword: "",
        },
        validationSchema: Yup.object({
            currentPassword: Yup.string()
                .required("Current password is required"),
            newPassword: Yup.string()
                .required("New password is required")
                .min(6, "Password must be at least 6 characters long"),
            reenterPassword: Yup.string()
                .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                .required("Please re-enter your new password"),
        }),
        onSubmit: handlePasswordChange,
    });
    const handleWalletPasswordChange = async (values) => {
        setIsWalletLoading(true);

        try {
            const response = await axios.post(
                "https://together-v.com/api/v1/agent/updateWalletPasswordByAgent",
                {
                    oldPass: values.currentWalletPassword,
                    newPass: values.newWalletPassword,
                },
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("AgentToken")}`,
                    },
                }
            );

            if (response.data.message === "Password updated successfully") {
                toast.success("Wallet password updated successfully!");
            } else {
                toast.error("Failed to update wallet password");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        } finally {
            setIsWalletLoading(false);
        }
    };


    const walletPasswordFormik = useFormik({
        initialValues: {
            currentWalletPassword: "",
            newWalletPassword: "",
        },
        validationSchema: Yup.object({
            currentWalletPassword: Yup.string().required("Current wallet password is required"),
            newWalletPassword: Yup.string()
                .required("New wallet password is required")
                .min(6, "Wallet password must be at least 6 characters long"),
        }),
        onSubmit: handleWalletPasswordChange,
    });


    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setSelectedImage(file);

        // Generate a preview
        const reader = new FileReader();
        reader.onload = (event) => {
            setPreviewImage(event.target.result);
        };
        reader.readAsDataURL(file);

        // Automatically upload after selecting the file
        await uploadProfileImage(file);
    };

    const uploadProfileImage = async (file) => {
        const formData = new FormData();
        formData.append("img", file);

        try {
            setIsUploading(true); // Start loader

            const response = await axios.post(
                "https://together-v.com/api/v1/agent/profileImage",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("AgentToken")}`,
                    },
                    withCredentials: true,
                }
            );

            if (response.data?.message === "Cover image product updated successfully") {
                toast.success("Profile image uploaded successfully!");
            } else {
                toast.error("Failed to upload the image.");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred during upload.");
        } finally {
            setIsUploading(false); // Stop loader
        }
    };

    const handleCoverFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setSelectedCoverImage(file);

        // Generate a preview
        const reader = new FileReader();
        reader.onload = (event) => {
            setPreviewCoverImage(event.target.result);
        };
        reader.readAsDataURL(file);

        // Automatically upload after selecting the file
        await uploadCoverImage(file);
    };

    // Upload cover image function
    const uploadCoverImage = async (file) => {
        const formData = new FormData();
        formData.append("img", file);

        try {
            setIsCoverUploading(true); // Start loader

            const response = await axios.post(
                "https://together-v.com/api/v1/agent/backgroundImage",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("AgentToken")}`, // Add token to the request
                    },
                    withCredentials: true,
                }
            );

            if (response.data?.message === "Background image product updated successfully") {
                toast.success("Cover image uploaded successfully!");
            } else {
                toast.error("Failed to upload the cover image.");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred during upload.");
        } finally {
            setIsCoverUploading(false); // Stop loader
        }
    };
    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true
        });
    }

    let { data: prof } = useQuery("getCurrentAgent", getCurrentAgent);
    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                    <title>Profile</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>

                </div>
                <div className="d-flex mx-auto">
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link "
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span>{t("language")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("en")}
                                >
                                    English
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("ar")}
                                >
                                    العربية
                                </button>
                            </li>
                        </ul>
                    </li>
                </div>


                <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">
                        <li class="nav-item dropdown ">
                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                {data?.data.data.profileImage ? (
                                    <img
                                        src={data?.data.data.profileImage}
                                        alt="Profile"
                                        className="rounded-circle"
                                    />
                                ) : (
                                    <CgProfile size={30} className="rounded-circle" />
                                )}                                <span class="d-none d-md-block dropdown-toggle ps-2">{data?.data.data.firstName} {data?.data.data.secondName}</span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>{t("my_profile")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>{t("sign_out")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <h2 className="menu-icon" onClick={toggleSidebar}>
                    <FiAlignJustify />
                </h2>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-tree"></i>
                            <span>{t("my_tree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-person-plus"></i>
                            <span>{t("add_agent")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box"></i>
                            <span>{t("all_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box"></i>
                            <span>{t("my_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-wallet2"></i>
                            <span>{t("my_wallet")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-arrow-left-right"></i>
                            <span>{t("transfers")}</span>
                        </Link>
                    </li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-file-earmark-text"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    {t("balance_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    {t("transfer_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    {t("withdraw_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/BinaryDetailsAgent">
                                    {t("binary_details")}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-cash"></i>
                            <span>{t("withdraw")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/profile">
                            <i className="bi bi-person"></i>
                            <span>{t("my_profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-calendar"></i>
                            <span>{t("all_events")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-telephone"></i>
                            <span>{t("contact_us")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <main id="main" className="main bg-light-2">
                <div className="pagetitle">
                    <h1>{t("profile")}</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/dashboard">{t("home")}</a></li>
                            <li className="breadcrumb-item">{t("users")}</li>
                            <li className="breadcrumb-item active">{t("profile")}</li>
                        </ol>
                    </nav>
                </div>


                <section className="section profile mt-5">
                    <div className="col-xl-6 mt-4">
                        <h5>{t("cover_image")}</h5>
                        <img
                            id="coverImagePreview"
                            src={data?.data.data.backgroundImage || previewCoverImage}
                            alt="Cover"
                            className="rounded"
                            style={{ width: "100%", height: "200px", objectFit: "cover" }}
                        />

                        {/* File Input */}
                        <input
                            type="file"
                            id="coverImageInput"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleCoverFileChange}
                        />

                        {/* Button */}
                        <button
                            type="button"
                            className="button blue-1 border py-2 px-3 mt-3"
                            onClick={() => {
                                // Trigger file selection
                                document.getElementById("coverImageInput").click();
                            }}
                        >
                            {isCoverUploading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                t("change_upload_cover_image")
                            )}
                        </button>
                    </div>

                    <div className="row justify-content-between mt-5">

                        <div class="col-xl-4 mt-5">
                            <div class="card border-0 mb-xl-0 mb-3 mt-5">
                                <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                    <img
                                        id="profileImagePreview"
                                        src={data?.data.data.profileImage
                                        }
                                        alt="Profile"
                                        className="rounded-circle"
                                        style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                    />

                                    {/* File Input */}
                                    <input
                                        type="file"
                                        id="profileImageInput"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />

                                    {/* Buttons */}
                                    <button
                                        type="button"
                                        className="button blue-1 border py-2 px-3 mt-3"
                                        onClick={() => {
                                            // Trigger file selection
                                            document.getElementById("profileImageInput").click();
                                        }}
                                    >
                                        {isUploading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            t("change_upload_image")
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 mt-5">
                            <div className="card border-0 mt-5">
                                <div className="card-body p-3">
                                    <ul className="nav nav-tabs border-bottom" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="overview-tap" data-bs-toggle="pill"
                                                data-bs-target="#profile-overview" type="button" role="tab" aria-controls="profile-overview"
                                                aria-selected="true">
                                                {t("overview")}
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="edit-tap" data-bs-toggle="pill" data-bs-target="#profile-edit"
                                                type="button" role="tab" aria-controls="profile-edit" aria-selected="false" tabIndex="-1">
                                                {t("edit_profile")}
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="change-password" data-bs-toggle="pill"
                                                data-bs-target="#profile-change-password" type="button" role="tab"
                                                aria-controls="profile-change-password" aria-selected="false" tabIndex="-1">
                                                {t("change_password")}
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-2">
                                        <div className="tab-pane fade profile-overview show active" id="profile-overview" role="tabpanel"
                                            aria-labelledby="#overview-tap">
                                            <h5 className="card-title py-3 mb-2">{t("profile_details")}</h5>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">{t("full_name")}</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.firstName} {data?.data.data.secondName} {data?.data.data.thirdName} {data?.data.data.lastName}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">{t("country")}</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.country}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">{t("phone")}</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.phone}</div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3 col-md-4 label">{t("email")}</div>
                                                <div className="col-lg-9 col-md-8">{data?.data.data.email}</div>
                                            </div>
                                        </div>


                                        <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
                                            <form onSubmit={handleProfileUpdate}>

                                                <div className="row mb-3">
                                                    <label htmlFor="address" className="col-md-4 col-lg-3 col-form-label">{t("address")}</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="address"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.address || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="country" className="col-md-4 col-lg-3 col-form-label">{t("country")}</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="country"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.country || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="governorate" className="col-md-4 col-lg-3 col-form-label">{t("governorate")}</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="governorate"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.governorate || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="city" className="col-md-4 col-lg-3 col-form-label">{t("city")}</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="city"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.city || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="inheritorName" className="col-md-4 col-lg-3 col-form-label">{t("inheritorName")}</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="inheritorName"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.inheritorName || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="grandfatherInheritorName" className="col-md-4 col-lg-3 col-form-label">{t("motherInheritorName")}</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="grandfatherInheritorName"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.grandfatherInheritorName || ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="zipCode" className="col-md-4 col-lg-3 col-form-label">{t("zipCode")}</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="zipCode"
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={data?.data?.data.zipCode || ""}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="text-center">
                                                    <button type="submit" className="button dark-1 bg-blue-1 md text-white">
                                                        {t("save_changes")}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>


                                        <div
                                            className="tab-pane fade profile-change-password pt-3"
                                            id="profile-change-password"
                                            role="tabpanel"
                                            aria-labelledby="#change-password"
                                        >
                                            <form onSubmit={passwordFormik.handleSubmit}>
                                                <div className="row mb-3">
                                                    <label htmlFor="currentPassword" className="col-md-4 col-lg-3 col-form-label">
                                                        {t("current_password")}
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="currentPassword"
                                                            type="password"
                                                            className="form-control"
                                                            id="currentPassword"
                                                            value={passwordFormik.values.currentPassword}
                                                            onChange={passwordFormik.handleChange}
                                                            onBlur={passwordFormik.handleBlur}
                                                        />
                                                        {passwordFormik.touched.currentPassword && passwordFormik.errors.currentPassword && (
                                                            <div className="text-danger">{passwordFormik.errors.currentPassword}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="newPassword" className="col-md-4 col-lg-3 col-form-label">
                                                        {t("new_password")}
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="newPassword"
                                                            type="password"
                                                            className="form-control"
                                                            id="newPassword"
                                                            value={passwordFormik.values.newPassword}
                                                            onChange={passwordFormik.handleChange}
                                                            onBlur={passwordFormik.handleBlur}
                                                        />
                                                        {passwordFormik.touched.newPassword && passwordFormik.errors.newPassword && (
                                                            <div className="text-danger">{passwordFormik.errors.newPassword}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="reenterPassword" className="col-md-4 col-lg-3 col-form-label">
                                                        {t("reenter_new_password")}
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="reenterPassword"
                                                            type="password"
                                                            className="form-control"
                                                            id="reenterPassword"
                                                            value={passwordFormik.values.reenterPassword}
                                                            onChange={passwordFormik.handleChange}
                                                            onBlur={passwordFormik.handleBlur}
                                                        />
                                                        {passwordFormik.touched.reenterPassword && passwordFormik.errors.reenterPassword && (
                                                            <div className="text-danger">{passwordFormik.errors.reenterPassword}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="text-center">
                                                    <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                        {isLoading ? (
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        ) : (
                                                            t("change_password")
                                                        )}
                                                    </button>
                                                </div>



                                            </form>
                                            <form onSubmit={walletPasswordFormik.handleSubmit}>
                                                <div className="row my-3">
                                                    <label
                                                        htmlFor="currentWalletPassword"
                                                        className="col-md-4 col-lg-3 col-form-label"
                                                    >
                                                        {t("enter_ewalletpassword")}
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="currentWalletPassword"
                                                            type="password"
                                                            className="form-control"
                                                            id="currentWalletPassword"
                                                            value={walletPasswordFormik.values.currentWalletPassword}
                                                            onChange={walletPasswordFormik.handleChange}
                                                            onBlur={walletPasswordFormik.handleBlur}
                                                        />
                                                        {walletPasswordFormik.touched.currentWalletPassword &&
                                                            walletPasswordFormik.errors.currentWalletPassword && (
                                                                <div className="text-danger">
                                                                    {walletPasswordFormik.errors.currentWalletPassword}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label
                                                        htmlFor="newWalletPassword"
                                                        className="col-md-4 col-lg-3 col-form-label"
                                                    >
                                                        {t("reenter_new_ewalletpassword")}
                                                    </label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input
                                                            name="newWalletPassword"
                                                            type="password"
                                                            className="form-control"
                                                            id="newWalletPassword"
                                                            value={walletPasswordFormik.values.newWalletPassword}
                                                            onChange={walletPasswordFormik.handleChange}
                                                            onBlur={walletPasswordFormik.handleBlur}
                                                        />
                                                        {walletPasswordFormik.touched.newWalletPassword &&
                                                            walletPasswordFormik.errors.newWalletPassword && (
                                                                <div className="text-danger">
                                                                    {walletPasswordFormik.errors.newWalletPassword}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        disabled={isWalletLoading}
                                                    >
                                                        {isWalletLoading ? (
                                                            <span
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        ) : (
                                                            "Change Wallet Password"
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >


        </>
    );
}

export default Profile;
