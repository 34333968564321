import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext"
import logoo from "../../imges/logo.png"
import { useTranslation } from "react-i18next";

function VerifyAgent() {
    const navigate = useNavigate();
    const [code, setCode] = useState(""); // State for verification code
    const [loading, setLoading] = useState(false); // State for button loading
    const [value, setValue] = useState(null); // State for the E-Pin value
    let params = useParams();
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    // Function to handle verification
    async function handleVerify(e) {
        e.preventDefault(); // Prevent default form submission behavior
        setLoading(true); // Set loading to true
        try {
            const response = await axios.post(
                `https://together-v.com/api/v1/agent/verifyAgentDirectly/${params.id}`,
                { code }, // Send the code in the request body
                {
                    withCredentials: true, // Include credentials
                }
            );

            if (response.data) {
                toast.success("Agent verified successfully");
                setCode(""); // Clear the input field
                navigate("/login");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "Verification failed.");
            console.log(error)
        } finally {
            setLoading(false); // Reset loading to false
        }
    }

    // Function to fetch and display E-Pin value
    const handleEpinFetch = async () => {
        if (!code.trim()) {
            toast.error("Please enter an agent ID.");
            return;
        }

        try {
            const response = await axios.get("https://together-v.com/api/v1/agent/E_PinsStats", {
                params: { epin: code },
                withCredentials: true,
            });

            if (response?.data?.data?.length > 0) { // Check if the array has data
                const epinData = response.data.data[0]; // Get the first object in the array
                setValue(epinData.value); // Set the value state
            } else {
                toast.error("No results found for this E-Pin.");
            }
        } catch (error) {
            toast.error("Error fetching E-Pin data. Please try again later.");
        }
    };

    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Verify</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            
                        />
                    </div>
                    <i class="bi bi-list toggle-sidebar-btn"></i>
                </div>
            </header>


            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Verify Agent</h1>
                </div>

                <section className="section">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Enter Verification Code</h5>
                                    <form onSubmit={handleVerify}>
                                        <div className="form-group">
                                            <label htmlFor="code">Verification Code</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="code"
                                                value={code}
                                                onChange={(e) => setCode(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="text-center mt-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary me-2"
                                                disabled={loading} // Disable button when loading
                                            >
                                                {loading ? (
                                                    <>
                                                        <span
                                                            className="spinner-border spinner-border-sm me-2"
                                                            role="status"
                                                        />
                                                        Verifying...
                                                    </>
                                                ) : (
                                                    "Verify"
                                                )}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={handleEpinFetch}
                                            >
                                                Fetch E-Pin Value
                                            </button>
                                        </div>
                                    </form>
                                    {value !== null && (
                                        <div className="mt-3">
                                            <h6 className="d-flex ">E-Pin Value: <p className="ms-2">{value}</p></h6>

                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default VerifyAgent;
