import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext"
import { useQuery } from "react-query";
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { IoIosSettings } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { CgProfile } from "react-icons/cg";

function Transfer() {
    const [agentId, setAgentId] = useState(""); // State for Agent ID
    const [amount, setAmount] = useState(""); // State for Amount
    const [walletPassword, setWalletPassword] = useState(""); // State for Wallet Password
    const [agentDetails, setAgentDetails] = useState(null); // State to store fetched agent details
    const { logOut } = useContext(AgentContext);
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Loading state for Transfer button

    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const { data, refetch } = useQuery("getCurrentAgent", () =>
        axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true,
        })
    );

    const handleAgentIdChange = async (e) => {
        const enteredId = e.target.value;
        setAgentId(enteredId);

        // Fetch agent details dynamically when agentId is updated
        if (enteredId) {
            try {
                const response = await axios.get(`https://together-v.com/api/v1/agent/getAgentByAdmin/${enteredId}`, {
                    withCredentials: true,
                });
                setAgentDetails(response.data.data);
                console.log("set", response.data.data) // Store fetched agent details
            } catch (error) {
                setAgentDetails(null); // Clear details if API fails
                console.log(error)
            }
        } else {
            setAgentDetails(null); // Clear details if input is empty
        }
    };

    async function handleTransfer(e) {
        e.preventDefault(); // Prevent default form submission
        setIsLoading(true); // Start loading

        try {
            const token = localStorage.getItem("AgentToken"); // Retrieve token from local storage

            const { data } = await axios.post(
                `https://together-v.com/api/v1/agent/transferBalance/${agentId}`, // Use the agentId in the API URL
                {
                    request: amount,
                    walletPassword,
                },
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`, // Include Authorization header
                    },
                }
            );

            if (data.message === "Balance transfer successfully") {
                toast.success("Balance transferred successfully");
                setAgentId(""); // Clear the Agent ID field
                setAmount(""); // Clear the Amount field
                setWalletPassword(""); // Clear the Wallet Password field
                setAgentDetails(null); // Clear agent details
                refetch(); // Reload the data
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                toast.error("Unauthorized. Please log in again.");
            } else {
                toast.error(err.response.data.message || "Failed to transfer balance.");
            }
        } finally {
            setIsLoading(false); // Stop loading
        }
    }

    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true
        });
    }

    let { data: prof } = useQuery("getCurrentAgent", getCurrentAgent);
    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Transfers</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>

                </div>
                <div className="d-flex mx-auto">
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link "
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span>{t("language")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("en")}
                                >
                                    English
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("ar")}
                                >
                                    العربية
                                </button>
                            </li>
                        </ul>
                    </li>
                </div>


                <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">
                        <li class="nav-item dropdown ">
                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                {data?.data.data.profileImage ? (
                                    <img
                                        src={data?.data.data.profileImage}
                                        alt="Profile"
                                        className="rounded-circle"
                                    />
                                ) : (
                                    <CgProfile size={30} className="rounded-circle" />
                                )}                                <span class="d-none d-md-block dropdown-toggle ps-2">{data?.data.data.firstName} {data?.data.data.secondName}</span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>{t("my_profile")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>{t("sign_out")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <h2 className="menu-icon" onClick={toggleSidebar}>
                    <FiAlignJustify />
                </h2>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-tree"></i>
                            <span>{t("my_tree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-person-plus"></i>
                            <span>{t("add_agent")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box"></i>
                            <span>{t("all_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box"></i>
                            <span>{t("my_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-wallet2"></i>
                            <span>{t("my_wallet")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-arrow-left-right"></i>
                            <span>{t("transfers")}</span>
                        </Link>
                    </li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-file-earmark-text"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    {t("balance_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    {t("transfer_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    {t("withdraw_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/BinaryDetailsAgent">
                                    {t("binary_details")}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-cash"></i>
                            <span>{t("withdraw")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/profile">
                            <i className="bi bi-person"></i>
                            <span>{t("my_profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-calendar"></i>
                            <span>{t("all_events")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-telephone"></i>
                            <span>{t("contact_us")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <section>
                <div id="main" className="bg-light-2">
                    <div className="pagetitle">
                        <h1>{t("transfer_money")}</h1>
                    </div>
                    <div className="body genealogy-body genealogy-scroll">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="col-12 bg-light  mb-4">
                                        <label
                                            className={`text-16 text-light-1 ${i18n.language === "ar" ? "text-end" : "text-start"}`}
                                        >
                                            {t("balance")}
                                        </label>

                                        <div className="form-control">
                                            <input
                                                type="text"
                                                value={data?.data.data.balance}
                                                disabled
                                            />
                                        </div>

                                    </div>
                                    <form onSubmit={handleTransfer}>
                                        <div className="col-12">
                                            <div className="form-input">
                                                <input
                                                    type="text"
                                                    value={agentId}
                                                    onChange={handleAgentIdChange}
                                                    required
                                                />
                                                <label className="lh-1 text-16 text-light-1">{t("agent_id")}</label>
                                            </div>
                                            {agentDetails && (
                                                <p className="text-success mt-1">
                                                    {t("agent_name")}: {agentDetails.firstName} {agentDetails.secondName} {agentDetails.thirdName} {agentDetails.lastName}
                                                </p>
                                            )}
                                        </div>
                                        <br />
                                        <div className="col-12">
                                            <div className="form-input">
                                                <input
                                                    type="number"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    required
                                                />
                                                <label className="lh-1 text-16 text-light-1">{t("amount")}</label>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="col-12">
                                            <div className="form-input">
                                                <input
                                                    type="text"
                                                    value={walletPassword}
                                                    onChange={(e) => setWalletPassword(e.target.value)}
                                                    required
                                                />
                                                <label className="lh-1 text-16 text-light-1">{t("wallet_password")}</label>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="col-auto">
                                            <button
                                                className="button md blue-1 bg-blue-1-05 text-blue-1 mt-2"
                                                type="submit"
                                                disabled={isLoading} // Disable button while loading
                                            >
                                                {isLoading ? t("transferring") : t("transfer")}
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Transfer;
