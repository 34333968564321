import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AgentContext } from "../../Context/AgentContext"
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";
import { IoIosSettings } from "react-icons/io";
import { CgProfile } from "react-icons/cg";

import { BallTriangle } from 'react-loader-spinner'
import { useTranslation } from "react-i18next";

function ProductDetailsAgent() {
    let { logOut } = useContext(AgentContext)
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let params = useParams();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    function getOnePro() {


        return axios.get(`https://together-v.com/api/v1/product/getProduct/${params.id}`, {

            withCredentials: true
        });
    }

    let { data, isLoading } = useQuery('getOnePro', getOnePro);

    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true
        });
    }

    let { data: prof } = useQuery("getCurrentAgent", getCurrentAgent);
    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);
    return (
        <>
            <header id="header" class="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                    <title>Product Details</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate('/Dashboard')}
                        />
                    </div>

                </div>
                <div className="d-flex mx-auto">
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link "
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span>{t("language")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("en")}
                                >
                                    English
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => changeLanguage("ar")}
                                >
                                    العربية
                                </button>
                            </li>
                        </ul>
                    </li>
                </div>


                <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">
                        <li class="nav-item dropdown ">
                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                {data?.data.data.profileImage ? (
                                    <img
                                        src={data?.data.data.profileImage}
                                        alt="Profile"
                                        className="rounded-circle"
                                    />
                                ) : (
                                    <CgProfile size={30} className="rounded-circle" />
                                )}                                <span class="d-none d-md-block dropdown-toggle ps-2">{data?.data.data.firstName} {data?.data.data.secondName}</span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                        <i className="bi bi-person"></i>
                                        <span>{t("my_profile")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" onClick={() => logOut()} >
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>{t("sign_out")}</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <h2 className="menu-icon" onClick={toggleSidebar}>
                    <FiAlignJustify />
                </h2>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/tree">
                            <i className="bi bi-tree"></i>
                            <span>{t("my_tree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/addagent">
                            <i className="bi bi-person-plus"></i>
                            <span>{t("add_agent")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProductsAgent">
                            <i className="bi bi-box"></i>
                            <span>{t("all_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyProducts">
                            <i className="bi bi-box"></i>
                            <span>{t("my_products")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyWallet">
                            <i className="bi bi-wallet2"></i>
                            <span>{t("my_wallet")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Transfer">
                            <i className="bi bi-arrow-left-right"></i>
                            <span>{t("transfers")}</span>
                        </Link>
                    </li>
                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-file-earmark-text"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to="/BalanceHistoryAgent">
                                    {t("balance_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/AgentTransferHistory">
                                    {t("transfer_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/MyWithdrawAgent">
                                    {t("withdraw_history")}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/BinaryDetailsAgent">
                                    {t("binary_details")}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDraw">
                            <i className="bi bi-cash"></i>
                            <span>{t("withdraw")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/profile">
                            <i className="bi bi-person"></i>
                            <span>{t("my_profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Events">
                            <i className="bi bi-calendar"></i>
                            <span>{t("all_events")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ContactUs">
                            <i className="bi bi-telephone"></i>
                            <span>{t("contact_us")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>


            <section>
                <div id="main" class="bg-light-2">
                    <div class="pagetitle">
                        <h1>{t("product_details")}</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/dashboard">{t("home")}</a></li>
                                <li className="breadcrumb-item">{t("dashboard")}</li>
                                <li className="breadcrumb-item active">{t("product_details")}</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="body genealogy-body genealogy-scroll">
                        <div
                            className="tab-pane fade active show"
                            id="all-booking"
                            role="tabpanel"
                        >
                            {isLoading ? <div className="'w-100 py-5 d-flex justify-content-center">
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#4fa94d"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div> : <>
                                <div className="container">
                                    <div className="row">
                                        {/* Render all images */}
                                        {data?.data?.data?.imgs?.length > 0 ? (
                                            data.data.data.imgs.map((imgUrl, index) => (
                                                <div key={index} className="col-lg-4 mb-3">
                                                    <img
                                                        src={imgUrl}
                                                        alt={`Product Image ${index + 1}`}
                                                        className="h-200px w-100"
                                                    />
                                                </div>
                                            ))
                                        ) : (
                                            <p>{t("no_images_available")}</p>
                                        )}
                                    </div>

                                    <div
                                        align={i18n.language === "ar" ? "right" : "left"}
                                        className="mt-4"
                                    >
                                        <p>
                                            <strong>{t("category")}:</strong> {data?.data?.data?.category || t("n_a")}
                                        </p>
                                        <h3>
                                            {t("product_name")}: {data?.data?.data?.name || t("n_a")}
                                        </h3>
                                        <p>
                                            <strong>{t("price")}:</strong> {data?.data?.data?.price || t("n_a")} {t("currency")}
                                        </p>
                                        <p>
                                            <strong>{t("pv")}:</strong> {data?.data?.data?.pv || t("n_a")}
                                        </p>
                                        <h4>{t("product_description")}:</h4>
                                        <p>
                                            {data?.data?.data?.description || t("no_description_available")}
                                        </p>
                                    </div>

                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}

export default ProductDetailsAgent;
