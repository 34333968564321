import logo from './logo.svg';
import './App.css';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from '../src/Components/Layout';
import Login from '../src/Components/Authentication/Login';
import Register from '../src/Components/Authentication/Register';
import ForgetPassword from '../src/Components/Authentication/ForgetPassword';
import { useTranslation } from 'react-i18next';

import Dashboard from '../src/Components/Agent/Dashboard';
import Tree from '../src/Components/Agent/Tree';
import AddAgent from '../src/Components/Agent/AddAgent';
import Profile from '../src/Components/Agent/Profile';
import Home from '../src/Components/Home';
import { Toaster } from 'react-hot-toast';
import AllProducts from '../src/Components/Admin/AllProducts';
import AddProduct from '../src/Components/Admin/AddProduct';
import AllProductsAgent from '../src/Components/Agent/AllProductsAgent';
import Transfer from '../src/Components/Agent/Transfer';
import WithDraw from '../src/Components/Agent/WithDraw';
import WithDrawRequest from '../src/Components/Admin/WithDrawRequest';
import TransferRequests from '../src/Components/Admin/TransferRequests';
import AllRequestsProducts from '../src/Components/Admin/AllRequestsProducts';
import ViewProduct from '../src/Components/Admin/ViewProduct';
import ViewCompletedRequests from '../src/Components/Admin/ViewCompletedRequests';
import ProductDetailsAdmin from '../src/Components/Admin/ProductDetailsAdmin';
import UpdateProductData from '../src/Components/Admin/UpdateProductData';
import ViewPurcahsesRequests from '../src/Components/Admin/ViewPurcahsesRequests';
import MyProducts from '../src/Components/Agent/MyProducts';
import ContactUs from '../src/Components/Agent/ContactUs';
import AllContactUs from '../src/Components/Admin/AllContactUs';
import AddEvent from './Components/Admin/AddEvent';
import Events from '../src/Components/Agent/Events';
import ViewAllEventsAdmin from '../src/Components/Admin/ViewAllEventsAdmin';
import Filtration from '../src/Components/Admin/Filtration';
import MyWallet from '../src/Components/Agent/MyWallet';
import VerifyAgent from '../src/Components/Agent/VerifyAgent';
import AdminProtectedRoute from '../src/Components/Admin/AdminProtectedRoute';
import AgentProtectedRoute from '../src/Components/Agent/AgentProtectedRoute';
import EpinHistory from '../src/Components/Admin/EpinHistory';
import UpdateProductImage from '../src/Components/Admin/UpdateProductImage';
import AddAgentAdmin from '../src/Components/Admin/AddAgentAdmin';
import MyTransfers from '../src/Components/Admin/MyTransfers';
import MyTreeAdmin from '../src/Components/Admin/MyTreeAdmin';
import AllWithDrawsAdmin from '../src/Components/Admin/AllWithDrawsAdmin';
import AgentTransferHistory from '../src/Components/Agent/AgentTransferHistory';
import BalanceHistoryAgent from '../src/Components/Agent/BalanceHistoryAgent';
import ProductDetailsAgent from '../src/Components/Agent/ProductDetailsAgent';
import UpdateDataAgent from '../src/Components/Admin/UpdateDataAgent';
import MyWithdrawAgent from '../src/Components/Agent/MyWithdrawAgent';
import BlockPage from '../src/Components/Agent/BlockPage';
import TreeDetails from '../src/Components/Admin/TreeDetails';
import TreeDetailsAgent from '../src/Components/Agent/TreeDetailsAgent';
import BinaryReports from '../src/Components/Admin/BinaryReports';
import ViewProductBoughtDetails from '../src/Components/Admin/ViewProductBoughtDetails';
import BinaryDetailsAgent from '../src/Components/Agent/BinaryDetailsAgent';
import ViewInsideEvent from '../src/Components/Admin/ViewInsideEvent';
import NewId from '../src/Components/Admin/NewId';
import ViewAgentEventss from '../src/Components/Agent/ViewAgentEventss';

import DashboardAdmin from '../src/Components/Admin/DashboardAdmin';
import Agents from '../src/Components/Admin/Agents';
import EPin from '../src/Components/Admin/EPin';
import AddEpin from '../src/Components/Admin/AddEpin';
import ProfileAdmin from '../src/Components/Admin/ProfileAdmin';
import { useContext, useEffect } from 'react';
import { AdminContext } from './Context/AdminContext';
import { AgentContext } from './Context/AgentContext';
let routers = createBrowserRouter([
  {
    path: '/', element: <Layout />, children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '/Dashboard', element: <AgentProtectedRoute><Dashboard /></AgentProtectedRoute> },
      { path: 'Tree', element: <AgentProtectedRoute><Tree /></AgentProtectedRoute> },
      { index: true, element: <Home /> },
      { path: 'Transfer', element: <AgentProtectedRoute><Transfer /></AgentProtectedRoute> },
      { path: 'Filtration', element: <AdminProtectedRoute><Filtration /></AdminProtectedRoute> },
      { path: 'MyWallet', element: <AgentProtectedRoute><MyWallet /></AgentProtectedRoute> },
      { path: 'VerifyAgent/:id', element: <VerifyAgent /> },
      { path: 'EpinHistory', element: <AdminProtectedRoute><EpinHistory /></AdminProtectedRoute> },
      { path: 'UpdateProductImage/:id', element: <AdminProtectedRoute><UpdateProductImage /></AdminProtectedRoute> },
      { path: 'AddAgentAdmin', element: <AdminProtectedRoute><AddAgentAdmin /></AdminProtectedRoute> },
      { path: 'MyTransfers', element: <AdminProtectedRoute><MyTransfers /></AdminProtectedRoute> },
      { path: 'MyTreeAdmin', element: <AdminProtectedRoute><MyTreeAdmin /></AdminProtectedRoute> },
      { path: 'AllWithDrawsAdmin', element: <AdminProtectedRoute><AllWithDrawsAdmin /></AdminProtectedRoute> },
      { path: 'BalanceHistoryAgent', element: <AgentProtectedRoute><BalanceHistoryAgent /></AgentProtectedRoute> },
      { path: 'AgentTransferHistory', element: <AgentProtectedRoute><AgentTransferHistory /></AgentProtectedRoute> },
      { path: 'MyWithdrawAgent', element: <AgentProtectedRoute><MyWithdrawAgent /></AgentProtectedRoute> },
      { path: 'TreeDetails/:id', element: <AdminProtectedRoute><TreeDetails /></AdminProtectedRoute> },
      { path: 'TreeDetailsAgent/:id', element: <AgentProtectedRoute><TreeDetailsAgent /></AgentProtectedRoute> },
      { path: 'ViewProductBoughtDetails/:id', element: <AdminProtectedRoute><ViewProductBoughtDetails /></AdminProtectedRoute> },
      { path: 'BinaryDetailsAgent', element: <AgentProtectedRoute><BinaryDetailsAgent /></AgentProtectedRoute> },
      { path: 'ViewInsideEvent/:id', element: <AdminProtectedRoute><ViewInsideEvent /></AdminProtectedRoute> },
      { path: 'NewId', element: <AdminProtectedRoute><NewId /></AdminProtectedRoute> },
      { path: 'ViewAgentEventss/:id', element: <AgentProtectedRoute><ViewAgentEventss /></AgentProtectedRoute> },

      { path: 'ProductDetailsAgent/:id', element: <AgentProtectedRoute><ProductDetailsAgent /></AgentProtectedRoute> },
      { path: 'UpdateDataAgent/:id/:di', element: <AdminProtectedRoute><UpdateDataAgent /></AdminProtectedRoute> },
      { path: 'ForgetPassword', element: <ForgetPassword /> },
      { path: 'BlockPage', element: <BlockPage /> },
      { path: 'BinaryReports', element: <AdminProtectedRoute><BinaryReports /></AdminProtectedRoute> },

      { path: 'WithDrawRequest', element: <AdminProtectedRoute><WithDrawRequest /></AdminProtectedRoute> },
      { path: 'TransferRequests', element: <AdminProtectedRoute><TransferRequests /></AdminProtectedRoute> },
      { path: 'AllRequestsProducts', element: <AdminProtectedRoute><AllRequestsProducts /></AdminProtectedRoute> },
      { path: 'ViewProduct/:id/:sid', element: <AdminProtectedRoute><ViewProduct /></AdminProtectedRoute> },
      { path: 'ViewCompletedRequests', element: <AdminProtectedRoute><ViewCompletedRequests /></AdminProtectedRoute> },
      { path: 'ProductDetailsAdmin/:id', element: <AdminProtectedRoute><ProductDetailsAdmin /></AdminProtectedRoute> },
      { path: 'UpdateProductData/:id', element: <AdminProtectedRoute><UpdateProductData /></AdminProtectedRoute> },
      { path: 'ViewPurcahsesRequests', element: <AdminProtectedRoute><ViewPurcahsesRequests /></AdminProtectedRoute> },
      { path: 'MyProducts', element: <AgentProtectedRoute><MyProducts /> </AgentProtectedRoute> },
      { path: 'ContactUs', element: <AgentProtectedRoute> <ContactUs /></AgentProtectedRoute> },
      { path: 'AllContactUs', element: <AdminProtectedRoute> <AllContactUs /></AdminProtectedRoute> },
      { path: 'AddEvent', element: <AdminProtectedRoute><AddEvent /></AdminProtectedRoute> },
      { path: 'Events', element: <AgentProtectedRoute><Events /></AgentProtectedRoute> },
      { path: 'ViewAllEventsAdmin', element: <AdminProtectedRoute><ViewAllEventsAdmin /></AdminProtectedRoute> },

      { path: 'AddAgent', element: <AgentProtectedRoute> <AddAgent /></AgentProtectedRoute> },
      { path: 'Profile', element: <AgentProtectedRoute><Profile /></AgentProtectedRoute> },
      { path: 'DashboardAdmin', element: <AdminProtectedRoute><DashboardAdmin /></AdminProtectedRoute> },
      { path: 'Agents', element: <AdminProtectedRoute><Agents /></AdminProtectedRoute> },
      { path: 'EPin', element: <AdminProtectedRoute><EPin /> </AdminProtectedRoute> },
      { path: 'AddEpin', element: <AdminProtectedRoute><AddEpin /></AdminProtectedRoute> },
      { path: 'ProfileAdmin', element: <AdminProtectedRoute><ProfileAdmin /></AdminProtectedRoute> },
      { path: 'AllProducts', element: <AdminProtectedRoute><AllProducts /></AdminProtectedRoute> },
      { path: 'AddProduct', element: <AdminProtectedRoute><AddProduct /></AdminProtectedRoute> },
      { path: 'AllProductsAgent', element: <AgentProtectedRoute><AllProductsAgent /></AgentProtectedRoute> },
      { path: 'WithDraw', element: <AgentProtectedRoute><WithDraw /></AgentProtectedRoute> },


    ]
  }
])
function App() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
  };

  useEffect(() => {
    const currentLanguage = i18n.language || 'en';
    document.body.setAttribute('dir', currentLanguage === 'ar' ? 'rtl' : 'ltr');
  }, [i18n.language]);

  let { setAgentToken, setAgentRefreshToken } = useContext(AgentContext);
  let { setAdminToken, setAdminRefreshToken } = useContext(AdminContext);


  useEffect(() => {
    if (localStorage.getItem('AgentToken') !== null && localStorage.getItem('AgentRefreshToken') !== null) {
      setAgentToken(localStorage.getItem('AgentToken'));
      setAgentRefreshToken(localStorage.getItem('AgentRefreshToken'));

    }
    else if (localStorage.getItem('AdminToken') !== null && localStorage.getItem('AdminRefreshToken')) {
      setAdminToken(localStorage.getItem('AdminToken'));
      setAdminRefreshToken(localStorage.getItem('AdminRefreshToken'));
    }

  }, [setAgentToken, setAdminToken, setAdminRefreshToken, setAgentRefreshToken]);

  return <>
    <div>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('ar')}>العربية</button>
    </div>
    <RouterProvider router={routers}></RouterProvider>
    <Toaster />
  </>
}
export default App;
